<template>
    <div class="main">
        <el-dialog :visible.sync='editDialog' title='編輯工作內容' width='30%'>
            <el-form ref='editForm' label-width='80px'>
                <el-form-item label='員工'>
                    <el-input type='text' v-model='editForm.name' disabled></el-input>
                </el-form-item>
                <el-form-item label='標題'>
                    <el-input type='text' v-model='editForm.title'></el-input>
                </el-form-item>
                <el-form-item label='內容'>
                    <el-input type='text' v-model='editForm.content'></el-input>
                </el-form-item>
                <el-form-item label='截止日期'>
                    <el-date-picker type="datetime" v-model="editForm.deadline" style="width: 100%"></el-date-picker>
                </el-form-item>
                <el-form-item label='提交日期'>
                    <el-date-picker type="datetime" v-model='editForm.submitTimestamp' style="width: 100%"></el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button type='primary' plain @click='updateTask'>更新</el-button> <el-button type='info' plain @click="editDialog = !editDialog">關閉</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog :visible.sync='dialogVisible' title='新增工作' width='40%'>
            <el-form ref='form' label-width='80px'>
                <el-form-item label='員工'> 
                    <el-select v-model='form.user' style='width: 100%' filterable>
                        <el-option v-for='(user, i) in userList' :key='i' :label='user.label' :value='user.value'>
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label='標題'>
                    <el-input type='text' v-model='form.title'>
                    </el-input>
                </el-form-item>
                <el-form-item label='截止日期'>
                    <el-date-picker type="datetime" v-model='form.deadline' style='width: 100%'>
                    </el-date-picker>
                </el-form-item>
                <el-form-item label='內容'>
                    <el-input type='textarea' rows='10' v-model='form.content'>
                    </el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type='primary' plain @click='submitTask'>提交</el-button> <el-button type='info' plain @click="dialogVisible = !dialogVisible">關閉</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-card class="card-box" shadow="never" v-loading='loading'>
            <div slot="header">
                <el-breadcrumb separator="/">
                    <el-breadcrumb-item to="/">主頁</el-breadcrumb-item>
                    <el-breadcrumb-item>工作分配</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
            <el-card shadow="never">
                <div slot="header">篩選</div>
                <el-form :inline="true" ref="filter">
                    <el-form-item>
                        <el-date-picker v-model='filter.deadline' clearable placeholder='截止日期'></el-date-picker>
                    </el-form-item>
                    <el-form-item>
                        <el-select v-model='filter.user' clearable filterable placeholder='員工'>
                            <el-option v-for='(user, i) in userList' :key='i' :label='user.label' :value='user.label'></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-select v-model='filter.status' change="tableData" clearable placeholder='狀態'>
                            <el-option label='進行中' value='進行中'></el-option>
                            <el-option label='已完成' value='已完成'></el-option>
                            <el-option label='過期完成' value='過期完成'></el-option>
                            <el-option label='已過期未完成' value='已過期未完成'></el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
            </el-card>
            <el-table :data="tableData" class="mt-1" height="600">
                <el-table-column label="標題" prop="title" sortable> </el-table-column>
                <el-table-column label="員工" prop="username" sortable>
                </el-table-column>
                <el-table-column label="截止日期" prop="deadline" sortable>
                </el-table-column>
                <el-table-column label='提交日期' prop='submitTimestamp' sortable>
                </el-table-column>
                <el-table-column label="狀態" sortable :sort-method='sortByStatus'>
                    <template slot-scope="scope">
                        <el-tag
                            type="danger"
                            v-if="scope.row.status == '已過期未完成'"
                        >
                            {{ scope.row.status }}
                        </el-tag>
                        <el-tag
                            type="success"
                            v-if="scope.row.status == '已完成'"
                        >
                            {{ scope.row.status }}
                        </el-tag>
                        <el-tag
                            type="warning"
                            v-if="scope.row.status == '進行中'"
                        >
                            {{ scope.row.status }}
                        </el-tag>
                        <el-tag
                            type="info"
                            v-if="scope.row.status == '過期完成'"
                        >
                            {{ scope.row.status }}
                        </el-tag>
                    </template>
                </el-table-column>
                <el-table-column>
                    <template slot="header" slot-scope="scope">
                        <el-input v-model="search" :id='scope' placeholder='搜尋標題' style="width: auto; float: left"></el-input>
                        <el-button type='primary' icon='el-icon-plus' circle plain style="float: left; margin-left: 1em" @click='dialogVisible = !dialogVisible'></el-button>
                    </template>
                    <template slot-scope="scope">
                        <el-button
                            type="primary"
                            plain
                            circle
                            icon="el-icon-edit-outline"
                            @click='editTask(scope)'
                        ></el-button>
                        <el-popconfirm
                            cancel-button-text='不用了'
                            confirm-button-text='好的'
                            title='確認刪除此工作內容嗎？'
                            @confirm='deleteTask(scope)'
                            >
                            <el-button
                                slot='reference'
                                type="danger"
                                plain
                                circle
                                icon="el-icon-delete"
                                style='margin-left: 1em;'
                            ></el-button>
                        </el-popconfirm>
                    </template>
                </el-table-column>
            </el-table>
        </el-card>
    </div>
</template>

<script>
import firebase from 'firebase/compat/app'

export default {
    name: "WorkAssign",
    data() {
        return {
            table: [
                /**
                 * content
                 * deadline
                 * username 
                 * title
                 * status
                 * task id
                 */
            ],
            filter: {
                status: '',
                user: '',
                deadline: '',
            },
            form: {
                user: '',
                deadline: '',
                title: '',
                content: '',
            },
            editForm: {
                uid: '',
                title: '',
                content: '',
                submitTimestamp: '',
                deadline: '',
                taskID: ''
            },
            search: '',
            loading_count: 0,
            userList: [],
            dialogVisible: false,
            editDialog: false,
        };
    },
    methods: {
        updateTask(){
            const taskID = this.editForm.taskID;
            const title = this.editForm.title;
            const content = this.editForm.content;
            const deadline = this.editForm.deadline;
            const submitTimestamp = this.editForm.submitTimestamp

            this.loading_count ++

            const tasksRef = firebase.database().ref('company/tasks/' + taskID)
            tasksRef.update({
                title: title,
                content: content,
                deadline: new Date(deadline).getTime(),
                submitted_timestamp: submitTimestamp == '' ? null : new Date(submitTimestamp).getTime()
            }).then(() => {
                this.$message({
                    message: `成功更改工作內容`,
                    type: 'success'
                })
            }).catch(err => {
                this.$message({
                    message: `發生錯誤 ${err.code} : ${err.message}`,
                    type: 'error'
                })
            }).finally(() =>{ 
                this.loading_count --
                this.editDialog = false
                this.getTasks()
            })
        },
        editTask(scope){
            const taskID = scope.row.taskID;
            const name = scope.row.username
            this.loading_count ++

            const tasksRef = firebase.database().ref('company/tasks/' + taskID)
            tasksRef.once('value').then(snapshot => snapshot.val())
            .then(value => {
                this.editForm.title = value.title;
                this.editForm.content = value.content;
                this.editForm.name = name;
                this.editForm.submitTimestamp = !value.submitted_timestamp ? '' : value.submitted_timestamp;
                this.editForm.deadline = value.deadline
                this.editForm.taskID = taskID
            }).catch(err => {
                this.$message({
                    message: `發生錯誤 ${err.code}: ${err.message}`,
                    type: 'error'
                })
            }).finally(() =>{
                this.editDialog = true
                this.loading_count --
            })
        },
        deleteTask(scope){
            const taskID = scope.row.taskID

            this.loading_count ++

            const tasksRef = firebase.database().ref('company/tasks/' + taskID)
            tasksRef.remove().then(() => {
                this.$message({
                    message: `已成功刪除該工作`,
                    type: 'success'
                })
            }).catch(err => {
                this.$message({
                    message: `發生錯誤 ${err.code}: ${err.message}`,
                    type: 'error'
                })
            }).finally(() => {
                this.getTasks()
                this.loading_count --
            })
        },
        submitTask() {
            const userID = this.form.user;
            const title = this.form.title;
            const deadline = this.form.deadline;
            const content = this.form.content;

            this.loading_count ++
            const tasksRef = firebase.database().ref('company/tasks')
            tasksRef.push({
                uid: userID,
                title: title,
                content: content,
                deadline: new Date(deadline).getTime(),
            }).then(() => {
                this.$message({
                    message: `成功新增工作`,
                    type: 'success'
                })
            }).catch(err => { 
                this.$message({
                    message: `發生錯誤 ${err.code}: ${err.message}`,
                    type: 'error'
                })
            }).finally(() => {
                this.loading_count --
                this.dialogVisible = false
                this.getTasks()

                this.form.user = ''
                this.form.title = ''
                this.form.deadline = ''
                this.form.content = ''
            })
        },
        getUsers(){
            this.userList = [];
            this.loading_count ++
            const usersRef = firebase.database().ref('users')
            usersRef.once('value').then(snapshot => snapshot.val())
            .then(value => {
                Object.keys(value).forEach(userID => {
                    const user = value[userID];
                    if(user.role != 'administrator'){
                        this.userList.push({
                            label: user.name,
                            value: userID
                        })
                    }
                })
            }).finally(() => {
                this.loading_count --
            })
        },
        getTasks(){
            this.table = []
            this.loading_count ++ 
            const tasksRef = firebase.database().ref('company/tasks/')
            tasksRef.once('value').then(snapshot => snapshot.val())
            .then(value => {
                Object.keys(value).forEach(taskID => {
                    const taskObj = value[taskID];
                    const usersRef = firebase.database().ref('users/' + taskObj.uid)
                    usersRef.once('value').then(s => s.val())
                    .then(v => {
                        let status = null;
                        if(taskObj.submitted_timestamp == null){ /** 未提交 */
                            if(new Date(taskObj.deadline).getTime() > new Date().getTime()){
                                status = '進行中'
                            }else {
                                status = '已過期未完成'
                            }
                        }else {
                            if(new Date(taskObj.deadline).getTime() > new Date(taskObj.submitted_timestamp).getTime()){
                                status = '已完成'
                            }else {
                                status = '過期完成'
                            }
                        }
                        this.table.push({
                            content: taskObj.content,
                            deadline: this.formatDate(taskObj.deadline) + ' ' + this.formatTime(taskObj.deadline),
                            submitTimestamp: taskObj.submitted_timestamp != null ? this.formatDate(taskObj.submitted_timestamp) + ' ' + this.formatTime(taskObj.submitted_timestamp) : '',
                            username: v.name,
                            title: taskObj.title,
                            taskID: taskID,
                            status: status,
                        })
                    })
                    /**
                     * content
                     * deadline
                     * username 
                     * title
                     * status
                     * task id
                     */
                })
            }).finally(() => {
                this.loading_count --
            })
        },
        /** format Time */
        formatTime(ts){
            var d = new Date(ts)
            return d.toString().slice(16,24)
        },
        /** format Date */
        formatDate(ts){
            let d = new Date(ts)
            return this.formatTen(d.getFullYear()) + '-' + this.formatTen(d.getMonth() + 1) + '-' + this.formatTen(d.getDate());
        },
        /** format 10 */
        formatTen(n){
            return n < 10 ? '0' + n : n
        },
        /** format */
        format: ts => {
            return this.formatDate(ts) + ' ' + this.formatTime(ts)
        },
        sortByStatus(a, b){
            return a.indexOf() > b.indexOf()
        },
        sortingBy() {
            const order = this.filter.order;
            switch (order) {
                case "name":
                    break;
                case "date":
                    this.table.sort((a, b) => {
                        if (
                            new Date(a.deadline).getTime() >
                            new Date(b.deadline).getTime()
                        )
                            return 1;
                        if (
                            new Date(a.deadline).getTime() <
                            new Date(b.deadline).getTime()
                        )
                            return -1;
                        return 0;
                    });
                    break;
            }
        },
        handleCurrentChange(c){ this.page = c;}
    },
    mounted(){
        this.getTasks();
        this.getUsers();
    },
    computed: {
        loading(){
            return !!this.loading_count
        },
        showTag(status) {
            if (status == "進行中") return "warning";
            if (status == "已完成") return "success";
            if (status == "過期完成") return "info";
            if (status == "已過期未完成") return "danger";
            return "";
        },
        tableData(){
            return this.table.filter(data => {
                var a = (this.filter.deadline && this.formatDate(this.filter.deadline) == data.deadline) || !this.filter.deadline
                var b = (this.filter.user && this.filter.user == data.username) || !this.filter.user
                var c = (this.filter.status && this.filter.status == data.status) || !this.filter.status
                var d = (this.search && data.title.toLowerCase().includes(this.search.toLowerCase())) || !this.search

                return a && b && c && d

            });
        },

    },
};
</script>
