<template>
    <div class="main">
        <el-row>
            <el-col
                :span="9"
                class="panel d-flex justify-content-center align-items-center"
            >
                <div>
                    <div class="app_name">全新人力資源管理系統</div>
                    <div style="font-size:50px; text-align: center">ACE SHOP LIMITED</div>
                    <div class="page_desc">管理員登入</div>
                    <el-form v-model="form" class="mt-5">
                        <el-input
                            v-model="form.email"
                            placeholder="電郵地址"
                        ></el-input>
                        <el-input
                            class="mt-1"
                            type="password"
                            v-model="form.password"
                            placeholder="密碼"
                        ></el-input>
                    </el-form>
                    <el-button
                        type="text"
                        class="forget_password"
                        @click="dialogVisible = !dialogVisible"
                    >
                        忘記你的密碼？
                    </el-button>
                    <!-- <div class="remember-me">
                        <el-checkbox label="記住我"></el-checkbox>
                    </div> -->
                    <div class="loginbox">
                        <el-button
                            class="login"
                            type="warning"
                            @click="onLogin"
                            :loading="loading"
                            >登入</el-button
                        >
                    </div>

                    <el-divider>或</el-divider>

                    <el-button
                        class="login_with_google"
                        type="primary"
                        plain
                        icon="el-icon-google custom-icon"
                        @click="onLoginWithGoogle"
                        :loading="loading"
                    >
                        使用Google登錄</el-button
                    >
                </div>
            </el-col>

            <el-col
                :span="15"
                class="panel d-flex justify-content-center align-items-center rightside-panel"
                :style="`background: ${panelColor}`"
            >
                <div>
                    <div class="panel-title">歡迎回來</div>
                    <el-image
                        :src="require('@/assets/LoginRightBg.png')"
                    ></el-image>
                </div>
            </el-col>
        </el-row>
        <el-dialog :visible.sync="dialogVisible" title="重設密碼" width="30%">
            <el-form ref="resetform" v-model="resetform">
                <el-form-item prop="email" label="郵箱" label-width="40px">
                    <el-input type="email" v-model="resetform.email"></el-input>
                </el-form-item>
                <el-form-item label-width="40px">
                    <el-button type="primary" plain @click="resetPassword"
                        >重設</el-button
                    >
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>
<style>
.el-icon-google {
    background: url("@/assets/gmail.png");
    background-size: cover;
}
.el-icon-google:before {
    content: "\8d3a" !important;
    font-size: 14px;
    visibility: hidden !important;
}
.main {
    background: ##fafafa;
}

.el-row {
    height: 100%;
}
.panel {
    height: 100%;
}
.app_name {
    text-align: center;
    font-size: 3em;
    margin: 0.5em;
    font-weight: 300;
}
.page_desc {
    text-align: center;
    font-size: 2em;

    font-weight: 400;
}
.forget_password {
    font-size: 0.8em;
    font-weight: 300;
    margin-top: 1em;
}
.remember-me {
    font-weight: 300;
    margin-top: 1em;
}
.loginbox {
    margin-top: 1em;
}
.login {
    width: 100%;
}
.or {
    margin-top: 1em;
}

.login_with_google {
    width: 100%;
    margin-top: 1em;
    position: relative;
}

.rightside-panel {
    height: 100%;
    border-radius: 50px 0 0 50px;
    box-shadow: -5px 4px 20px rgba(0, 0, 0, 0.25);
}
.panel-title {
    font-size: 4em;
    font-weight: bold;
    color: #595959;
}
.copyright {
    text-align: right;
    color: rgba(89, 89, 89, 0.8);
}
.google-icon {
    width: 24px;
    height: 24px;
    position: absolute;
    left: 90px;
    top: 7px;
}
</style>
<script>
import { panelColor } from "@/config";

import firebase from "firebase/compat/app";
export default {
    name: "Login",
    data() {
        return {
            panelColor,
            form: {
                email: "",
                password: "",
            },
            resetform: {
                email: "",
            },
            loading: false,
            dialogVisible: false,
        };
    },
    methods: {
        async onLogin() {
            this.loading = true;
            firebase
                .auth()
                .signInWithEmailAndPassword(this.form.email, this.form.password)
                .then((userCredential) => {
                    const user = userCredential.user;

                    const userRef = firebase
                        .database()
                        .ref(`users/${user.uid}`);
                    userRef
                        .once("value")
                        .then((snapshot) => {
                            const role = snapshot.val()["role"];
                            if (role == "administrator") {
                                this.$message({
                                    message: "登入成功",
                                    type: "success",
                                });
                                this.$router.push("/");
                            } else {
                                throw {
                                    message: "權限不足",
                                };
                            }
                        })
                        .catch((error) => {
                            this.$message({
                                message: `${error.message}`,
                                type: "error",
                            });
                            firebase.auth().signOut();
                        })
                        .finally(() => {
                            this.loading = false;
                        });
                })
                .catch((error) => {
                    let message = "";
                    switch (error.code) {
                        case "auth/user-not-found":
                            message = "找不到該用戶";
                            break;
                        case "auth/wrong-password":
                            message = "密碼錯誤";
                    }
                    this.$message({
                        message: message,
                        type: "error",
                    });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        async onLoginWithGoogle() {
            this.loading = true;
            let provider = new firebase.auth.GoogleAuthProvider();
            firebase
                .auth()
                .signInWithPopup(provider)
                .then((userCredential) => {
                    // var credential = result.credential;
                    const user = userCredential.user;

                    const userRef = firebase
                        .database()
                        .ref(`users/${user.uid}`);
                    userRef
                        .once("value")
                        .then((snapshot) => {
                            const role = snapshot.val()["role"];
                            if (role == "administrator") {
                                this.$message({
                                    message: "登入成功",
                                    type: "success",
                                });
                                this.$router.push("/");
                            } else {
                                throw {
                                    message: "權限不足",
                                };
                            }
                        })
                        .catch((error) => {
                            this.$message({
                                message: `${error.message}`,
                                type: "error",
                            });
                            firebase.auth().signOut();
                        })
                        .finally(() => {
                            this.loading = false;
                        });
                })
                .catch((error) => {
                    let message = "";
                    switch (error.code) {
                        case "auth/user-not-found":
                            message = "找不到該用戶";
                            break;
                        case "auth/wrong-password":
                            message = "密碼錯誤";
                            break;
                        default:
                            message = "發生錯誤";
                            break;
                    }
                    this.$message({
                        message: message,
                        type: "error",
                    });
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        async resetPassword() {
            firebase
                .auth()
                .sendPasswordResetEmail(this.resetform.email)
                .then(() => {
                    this.dialogVisible = false;
                    this.$message({
                        message: "已發送重設密碼郵件",
                        type: "info",
                    });
                })
                .catch((error) => {
                    console.error(error);
                    this.dialogVisible = false;
                    this.$message({
                        message: `${error.message}`,
                        type: "error",
                    });
                });
        },
    },
};
</script>
