<template>
    <div class="main">
        <el-dialog :visible.sync='editDialog' title='編輯通知' width='40%'>
            <el-form
                ref='editForm'
                v-model='editForm'
                label-width='80px'
            >
                <el-form-item label='標題'>
                    <el-input v-model="editForm.title" type='text'></el-input>
                </el-form-item>
                <el-form-item label='內容'>
                    <el-input v-model='editForm.content' type='textarea'/>
                </el-form-item>
                <el-form-item>
                    <el-button size='medium' type='primary' @click='updateNotification' plain>更新</el-button> <el-button @click="editDialog = !editDialog" plain>關閉</el-button>
                </el-form-item>
                
            </el-form>
        </el-dialog>
        <el-dialog :visible.sync="dialogVisible" title="新增通知" width="40%" top='10px'>
            <el-form
                size="mini"
                ref="noteForm"
                v-model="noteForm"
                label-width="80px"
            >
                <el-card>
                    <div slot="header">發送對象</div>
                    <el-row>
                        <el-col :span="8">

                            <el-form-item label="所有人" prop="noteForm.isAll">
                                <el-switch
                                    v-model="noteForm.isAll"
                                    active-color="#13ce66"
                                    inactive-color="#ff4949"
                                    :active-value="true"
                                    :inactive-value="false"
                                    :disabled="
                                        noteForm.isUser || noteForm.isDepartment
                                    "
                                    value="false"
                                    @change="updateObjects()"
                                ></el-switch>
                            </el-form-item>
                            <el-form-item
                                label="特定部門"
                                prop="noteForm.isDepartment"
                            >
                                <el-switch
                                    v-model="noteForm.isDepartment"
                                    active-color="#13ce66"
                                    :active-value="true"
                                    :inactive-value="false"
                                    inactive-color="#ff4949"
                                    :disabled="
                                        noteForm.isAll || noteForm.isUser
                                    "
                                    value="false"
                                    @change="updateObjects()"
                                ></el-switch>
                            </el-form-item>
                            <el-form-item label="特定員工">
                                <el-switch
                                    v-model="noteForm.isUser"
                                    active-color="#13ce66"
                                    inactive-color="#ff4949"
                                    :active-value="true"
                                    :inactive-value="false"
                                    :disabled="
                                        noteForm.isDepartment || noteForm.isAll
                                    "
                                    value="false"
                                    @change="updateObjects()"
                                ></el-switch>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="選擇對象">
                                <el-select
                                    multiple
                                    filterable
                                    filterablesize="medium"
                                    v-model="noteForm.objects"
                                    :disabled="!!noteForm.isAll"
                                    style="width: 100%"
                                >
                                    <el-option
                                        v-for="(item, i) in noteForm.list"
                                        :key="i"
                                        :label="item.label"
                                        :value="item.value"
                                    />
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-card>
                <el-card class="mt-1">
                    <div slot="header">通知</div>
                    <el-form-item
                        label="標題"
                        :rules="[
                            {
                                required: true,
                                message: '請輸入標題',
                                trigger: 'blur',
                            },
                        ]"
                    >
                        <el-input type="text" v-model="noteForm.title" />
                    </el-form-item>
                    <el-form-item
                        label="內容"
                        :rules="[
                            {
                                required: true,
                                message: '請輸入內容',
                                trigger: 'blur',
                            },
                        ]"
                    >
                        <el-input type="textarea" v-model="noteForm.content" />
                    </el-form-item>
                    <el-form-item>
                        <el-button
                            type="primary"
                            plain
                            size="medium"
                            @click="publishNote"
                            >發布</el-button
                        >
                        <el-button
                            type="info"
                            plain
                            size="medium"
                            @click="dialogVisible = !dialogVisible"
                            >取消</el-button
                        >
                    </el-form-item>
                </el-card>
            </el-form>
        </el-dialog>
        <el-card class="card-box" shadow="never" v-loading="loading">
            <div slot="header">
                <el-breadcrumb separator="/">
                    <el-breadcrumb-item to="/">主頁</el-breadcrumb-item>
                    <el-breadcrumb-item>通知</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
            <el-card shadow="never">
                <div slot="header">篩選</div>
                <el-form ref="filterForm" v-model="filterForm" :inline="true">
                    <el-form-item>
                        <el-select
                            filterable
                            v-model="filterForm.category"
                            @change="updateFilterList"
                        >
                        <el-option value='all' label='所有'>
                        </el-option>
                            <el-option value="everyone" label="所有人">
                            </el-option>
                            <el-option value="department" label="部門">
                            </el-option>
                            <el-option value="user" label="員工"> </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-select v-model="filterForm.object" clearable filterable>
                            <el-option
                                v-for="(item, i) in filter.list"
                                :key="i"
                                :value="item.value"
                                :label="item.label"
                                :disabled="filterForm.category == 'everyone'"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    
                </el-form>
            </el-card>
            <el-table :data="dataTable" class="mt-1">
                <el-table-column label="對象" width="280">
                    <template slot-scope="scope">
                        <span
                            v-if="scope.row.isDepartment"
                            ><span v-for="(obj, i) in scope.row.object" :key="i"
                                ><el-tag type='primary'>{{ obj }}</el-tag>
                            </span>
                        </span>
                        <span
                            v-if="scope.row.isUser"
                            ><span v-for="(obj, i) in scope.row.object" :key="i"
                                ><el-tag type='danger'>{{ obj }}</el-tag>
                            </span>
                        </span>
                        <span
                            v-if="scope.row.object === '所有人'"
                            ><el-tag type="success">所有人</el-tag></span
                        >
                    </template>
                </el-table-column>
                <el-table-column label="內容" width="1000">
                    <template slot-scope="scope">
                        <div style="font-weight: bold; font-size: 1.1em">
                            {{ scope.row.header }}
                        </div>
                        <div style="text-overflow: ellipsis; white-space: nowrap; overflow: hidden;">{{ scope.row.content }}</div>
                    </template>
                </el-table-column>
                <el-table-column
                    label="時間"
                    sortable
                    :sort-method="
                        (a, b) => {
                            a - b;
                        }
                    "
                >
                    <template slot-scope="scope">
                        <div style="font-weight: 300">
                            {{ formatTimestamp(scope.row.timestamp) }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="action" label="操作">
                    <template slot="header" slot-scope="scope">
                        <!-- header -->
                        <el-button
                            type="primary"
                            plain
                            :id="scope"
                            @click="dialogVisible = !dialogVisible"
                            >新增通知</el-button
                        >
                    </template>
                    <template slot-scope="scope">
                        <el-button
                            icon="el-icon-edit-outline"
                            circle
                            plain
                            type="primary"
                            @click="handleEdit(scope)"
                        ></el-button>
                        <el-popconfirm
                            title="確定要刪除此通知嗎？"
                            cancel-button-text="不用了"
                            confirm-button-text="好的"
                            @confirm="handleDelete(scope)"
                        >
                            <el-button
                                icon="el-icon-delete"
                                circle
                                plain
                                type="danger"
                                slot="reference"
                                style="margin-left: 1em"
                            ></el-button>
                        </el-popconfirm>
                    </template>
                </el-table-column>
            </el-table>
        </el-card>
    </div>
</template>

<style>
.el-textarea, .el-textarea__inner{
    height: 300px!important;
}
</style>

<script>
import firebase from "firebase/compat/app";

export default {
    name: "Notification",
    data() {
        return {
            dialogVisible: false,

            filter: {
                list: [],
            },
            filterForm: {
                object: [],
                category: 'all'
            },
            table: [],
            noteForm: {
                title: "",
                content: "",
                isDepartment: false,
                isUser: false,
                isAll: false,
                list: [],
                objects: [],
            },
            editForm: {
                title: '',
                content: ''
            },
            loading_count: 0,
            editDialog: false,
        };
    },
    computed: {
        loading() {
            return this.loading_count != 0;
        },
        dataTable(){
            if(this.filterForm.category == 'all') return this.table
            return this.table.filter(data=>{
                var cate = 'all';
                if(data.isAll) cate = 'everyone'
                if(data.isDepartment) cate = 'department'
                if(data.isUser) cate = 'user'
                if(this.filterForm.object == '' || this.filterForm.object == null){
                    return cate == this.filterForm.category
                }
                return data.object.includes(this.filterForm.object) && cate == this.filterForm.category
            })
        }
    },
    methods: {
        /* 更新 通知內容 */
        updateNotification() {
            // Get form data
            const id = this.editForm.id;
            const title = this.editForm.title;
            const content = this.editForm.content;

            // handle
            this.loading_count ++
            const ref = firebase.database().ref('company/notifications/' + id)
            ref.update({
                title: title,
                content: content
            }).then(()=>{
                this.loading_count -- 
                this.editDialog = false
                this.updateTable()
            })
        },
        /* 發布 通知 */
        publishNote() {
            this.$refs["noteForm"].validate((valid) => {
                if (!valid) {
                    return false;
                }
            });
            var push = {
                isAll: this.noteForm.isAll,
                isDepartment: this.noteForm.isDepartment,
                isUser: this.noteForm.isUser,
                title: this.noteForm.title,
                content: this.noteForm.content,
                timestamp: new Date().getTime(),
            };
            if (this.noteForm.isAll) {
                this.noteForm.isAll = true;
            } else if (this.noteForm.isDepartment) {
                var departments = {
                    departments: this.noteForm.objects,
                };
                push = Object.assign(departments, push);
            } else if (this.noteForm.isUser) {
                var users = {
                    users: this.noteForm.objects,
                };
                push = Object.assign(users, push);
            }
            this.loading_count++;
            const noteRef = firebase.database().ref("company/notifications");
            noteRef
                .push(push)
                .then(() => {
                    this.loading_count--;
                    this.dialogVisible = false;
                    this.noteForm.title = "";
                    this.noteForm.content = "";
                    this.noteForm.objects = [];
                    this.noteForm.isDepartment = false;
                    this.noteForm.isAll = false;
                    this.noteForm.isUser = false;
                })
                .finally(() => {
                    this.updateTable();
                });
        },
        /* 更新 新增部門介面的 名單 */
        updateObjects() {
            this.noteForm.objects = [];
            if (this.noteForm.isUser || this.noteForm.isDepartment)
                this.loading_count++;
            this.noteForm.list = [];
            if (
                !this.noteForm.isAll &&
                !this.noteForm.isDepartment &&
                this.noteForm.isUser
            ) {
                //選擇特定員工
                const userRef = firebase.database().ref("users");
                userRef
                    .once("value")
                    .then((snapshot) => snapshot.val())
                    .then((value) => {
                        Object.keys(value).forEach((v) => {
                            this.noteForm.list.push({
                                label: value[v].name,
                                value: v,
                            });
                        });
                    })
                    .finally(() => {
                        this.loading_count--;
                    });
            } else if (
                !this.noteForm.isAll &&
                this.noteForm.isDepartment &&
                !this.noteForm.isUser
            ) {
                //選擇特定部門
                const deptRef = firebase.database().ref("company/departments");
                deptRef
                    .once("value")
                    .then((snapshot) => snapshot.val())
                    .then((value) => {
                        Object.keys(value).forEach((v) => {
                            if (value[v].role != "administrator") {
                                this.noteForm.list.push({
                                    label: value[v].name,
                                    value: v,
                                });
                            }
                        });
                    })
                    .finally(() => {
                        this.loading_count--;
                    });
            }
        },
        /* 處理 刪除通知 */
        handleDelete(scope) {
            var noteID = scope.row.id;
            this.loading_count++;
            firebase
                .database()
                .ref("company/notifications/" + noteID)
                .remove()
                .then(() => {
                    this.updateTable();
                    this.loading_count--;
                    this.$message({
                        message: `刪除通知成功`,
                        type: `success`,
                    });
                })
                .catch((err) => {
                    this.$message({
                        message: `發生錯誤 ${err.code}: ${err.message}`,
                        type: `error`,
                    });
                });
        },
        /* 打開 編輯通知 介面 */
        handleEdit(scope) {
            
            this.editDialog = true
            this.editForm.id = scope.row.id;
            this.editForm.title = scope.row.header;
            this.editForm.content = scope.row.content;
        },
        /* 轉換時間格式 */
        formatTimestamp(timestamp) {
            const date = new Date(timestamp);
            return `${this.format(date.getYear() + 1900)}-${this.format(
                date.getMonth() + 1
            )}-${this.format(date.getDate())} ${this.format(
                date.getHours()
            )}:${this.format(date.getMinutes())}:${this.format(
                date.getSeconds()
            )}`;
        },
        /* 時間格式: a 低於 10 會轉成 "0" + a */
        format(a) {
            return a >= 10 ? a : "0" + a;
        },
        /* 更新 Table */
        updateTable() {
            this.table = [];
            this.loading_count++;
            firebase
                .database()
                .ref("company/notifications")
                .once("value")
                .then((snapshot) => snapshot.val())
                .then((value) => {
                    Object.keys(value).forEach((v) => {
                        var item = value[v];
                        if (
                            item.isAll
                        ) {
                            this.table.push({
                                header: item.title,
                                content: item.content,
                                timestamp: item.timestamp,
                                id: v,
                                    isAll: item.isAll,
                                    isDepartment: item.isDepartment,
                                    isUser: item.isUser,
                                object: "所有人",
                            });
                        } else if (
                            item.isUser
                        ) {
                            var users = item.users;
                            var userList = [];
                            users.forEach((user) => {

                                firebase
                                    .database()
                                    .ref("users/" + user)
                                    .once("value")
                                    .then((s) => s.val())
                                    .then((s) => {
                                        userList.push(s.name)
                                    });
                            });
                                this.table.push({
                                    header: item.title,
                                    content: item.content,
                                    timestamp: item.timestamp,
                                    id: v,
                                    object: userList,
                                    isAll: item.isAll,
                                    isDepartment: item.isDepartment,
                                    isUser: item.isUser,
                                });
                            
                        } else if (
                            item.isDepartment
                        ) {
                            var depts = item.departments;
                            var deptList = [];
                            depts.forEach((dept) => {
                                firebase
                                    .database()
                                    .ref("company/departments/" + dept)
                                    .once("value")
                                    .then((s) => s.val())
                                    .then((value) => {
                                        deptList.push(value.name);
                                    });
                            });
                                this.table.push({
                                    header: item.title,
                                    content: item.content,
                                    timestamp: item.timestamp,
                                    id: v,
                                    object: deptList,
                                    isAll: item.isAll,
                                    isDepartment: item.isDepartment,
                                    isUser: item.isUser,
                                });
                            
                        }
                    });
                })
                .finally(() => {
                    this.loading_count--;
                });
        },
        /* 更新篩選名單 */
        updateFilterList() {
            this.filter.list = [];
            this.filterForm.object = [];
            if (this.filterForm.category === "everyone") {
                this.filterForm.category = 'everyone'
            } else if (this.filterForm.category === "department") {
                this.loading_count++;
                firebase
                    .database()
                    .ref("company/departments/")
                    .once("value")
                    .then((_s) => _s.val())
                    .then((value) => {
                        Object.keys(value).forEach((_v) => {
                            this.filter.list.push({
                                label: value[_v].name,
                                value: value[_v].name,
                            });
                        });
                    })
                    .finally(() => {
                        this.loading_count--;
                    });
            } else if (this.filterForm.category === "user") {
                firebase
                    .database()
                    .ref("users")
                    .once("value")
                    .then((s) => s.val())
                    .then((v) => {
                        Object.keys(v).forEach((_v) => {
                            this.filter.list.push({
                                label: v[_v].name,
                                value: v[_v].name,
                            });
                        });
                    });
            }
            this.updateTable()
        },
    },
    mounted() {
        //this.table
        // object
        // header
        // content
        // timestamp
        // notification id
        this.updateTable();
    },
};
</script>
