<template>
    <div class="main">
        <el-card shadow="never" class="card-box" v-loading="loading">
            <div slot="header">
                <el-breadcrumb separator="/">
                    <el-breadcrumb-item to="/">主頁</el-breadcrumb-item>
                    <el-breadcrumb-item>員工</el-breadcrumb-item>
                </el-breadcrumb>
            </div>

            <el-table :data="pagedTableData" style="">
                <el-table-column label="員工">
                    <template slot-scope="scope">
                        <el-row>
                            <el-col :span="8"
                                ><el-avatar
                                    :size="40"
                                    :src="circleUrl"
                                ></el-avatar
                            ></el-col>
                            <el-col :span="16"
                                ><div>{{ scope.row.name }}</div>
                                <div>{{ scope.row.eng_name }}</div></el-col
                            >
                        </el-row>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="email"
                    label="郵箱"
                    sortable
                ></el-table-column>
                <el-table-column
                    prop="teleNo"
                    label="電話號碼"
                    sortable
                ></el-table-column>
                <el-table-column>
                    <template slot="header" slot-scope="scope">
                        <el-input
                            placeholder="搜尋"
                            style="float: left; width: auto"
                            :id="scope"
                            v-model="search"
                            clearable
                        ></el-input>
                        <el-button
                            @click="dialogVisible = true"
                            icon="el-icon-plus"
                            circle
                            style="float: left"
                            type="text"
                        ></el-button>
                    </template>
                    <template slot-scope="scope">
                        <el-button
                            circle
                            @click="viewInfo(scope)"
                            icon="el-icon-view"
                            plain
                            type="info"
                        ></el-button>
                        <el-button
                            @click="editInfo(scope)"
                            circle
                            icon="el-icon-edit-outline"
                            plain
                            style="margin-left: 1em"
                            type="primary"
                        ></el-button>
                        <el-popconfirm
                            confirm-button-text="好的"
                            cancel-button-text="不用了"
                            icon="el-icon-info"
                            icon-color="red"
                            title="確定要刪除此員工嗎？"
                            style="margin-left: 1em"
                            @confirm="confirmDelete(scope)"
                        >
                            <el-button
                                slot="reference"
                                plain
                                icon="el-icon-delete"
                                type="danger"
                                circle
                            ></el-button>
                        </el-popconfirm>
                    </template>
                </el-table-column>
            </el-table>
            <div class="mt-1">
                <el-pagination
                    @current-change="handleCurrentChange"
                    :current-page="page"
                    :page-size="8"
                    layout="total, prev, pager, next, jumper"
                    :total="total"
                >
                </el-pagination>
            </div>
        </el-card>
        <el-dialog
            :visible.sync="editDialog"
            title="修改員工資料"
            width="40%"
            v-loading="empLoading"
        >
            <el-form
                :model="editForm"
                ref="editForm"
                :rules="formRules"
                style="margin-left: 5em; margin-right: 5em"
                label-width="80px"
            >
                <el-form-item label="全名" prop="fullname">
                    <el-input
                        type="text"
                        v-model="editForm.fullname"
                        placeholder="輸入全名"
                    ></el-input>
                </el-form-item>
                <el-form-item label="英文名" prop="eng_name">
                    <el-input
                        type="text"
                        v-model="editForm.eng_name"
                        placeholder="輸入英文名"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="電話號碼" prop="teleNo">
                    <el-input
                        v-model="editForm.teleNo"
                        placeholder="輸入電話號碼"
                        style="width: 100%"
                    ></el-input>
                </el-form-item>
                <el-form-item label="地址" prop="address">
                    <el-input
                        v-model="editForm.address"
                        placeholder="輸入地址"
                        style="width: 100%"
                    ></el-input> </el-form-item
                ><el-form-item label="出生日期" prop="dob">
                    <el-date-picker
                        v-model="editForm.dob"
                        placeholder="輸入出生日期"
                        type="date"
                        style="width: 100%"
                    ></el-date-picker>
                </el-form-item>
                <el-form-item label="薪金" prop="salary">
                    <el-input
                        v-model="editForm.salary"
                        placeholder="輸入薪金"
                        type="number"
                        style="width: 100%"
                    ></el-input> </el-form-item
                ><el-form-item label="銀行" prop="bank">
                    <el-select
                        v-model="editForm.bank"
                        placeholder="輸入銀行"
                        style="width: 100%"
                        filterable
                        ><el-option
                            v-for="(_b, i) in bankList"
                            :key="i"
                            :value="_b.value"
                            :label="_b.label"
                        >
                        </el-option
                    ></el-select>
                </el-form-item>
                <el-form-item label="銀行帳戶" prop="bankaccount">
                    <el-input
                        v-model="editForm.bankaccount"
                        placeholder="輸入銀行帳戶"
                        style="width: 100%"
                    ></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" plain @click="onUpdateProfile"
                        >確定</el-button
                    >
                    <el-button type="primary" plain>取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog :visible.sync="dialogVisible" title="新增員工" width="40%">
            <el-form
                :model="form"
                ref="form"
                :rules="formRules"
                style="margin-left: 5em; margin-right: 5em"
                label-width="80px"
            >
                <el-steps :active="active" finish-status="success" align-center>
                    <el-step title="步驟 1"></el-step>
                    <el-step title="步驟 2"></el-step>
                    <el-step title="步驟 3"></el-step>
                </el-steps>

                <div v-show="active == 0" class="mt-1">
                    <center style="margin-bottom: 1em">
                        <vue-qr text="https://ace-shop.xyz/"></vue-qr>
                        <!-- <vue-qr bgSrc='https://docs.google.com/forms/d/1lv2juywjC-rT3CZBcOZ8z6sgwcK1SsFcQQtCTeD-XQ0/edit?hl=zh-tw' text="Hello world!" :size="200"></vue-qr> -->
                    </center>
                    <!-- <el-form-item label="頭像">
                        <el-upload
                            action="#"
                            :auto-upload="false"
                            list-type="picture-card"
                            :limit="1"
                            :class="hasUpload"
                            :on-change="onSuccess"
                            :on-success="onSuccess"
                            :before-upload="onSuccess"
                            :on-remove="onRemove"
                        >
                            <i slot="default" class="el-icon-plus"></i>
                        </el-upload>
                    </el-form-item> -->
                    <el-form-item label="電郵" prop="email">
                        <el-input
                            v-model="form.email"
                            placeholder="輸入電郵"
                            style="width: 100%"
                            type="text"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="密碼" prop="password">
                        <el-input
                            v-model="form.password"
                            type="password"
                            placeholder="輸入密碼"
                            style="width: 100%"
                        ></el-input>
                    </el-form-item>
                </div>
                <div v-show="active == 1" class="mt-2">
                    <el-form-item label="全名" prop="fullname">
                        <el-input
                            v-model="form.fullname"
                            placeholder="輸入全名"
                            style="width: 100%"
                            type="text"
                        ></el-input> </el-form-item
                    ><el-form-item label="英文名" prop="eng_name">
                        <el-input
                            v-model="form.eng_name"
                            placeholder="輸入英文名"
                            style="width: 100%"
                            type="text"
                        ></el-input> </el-form-item
                    ><el-form-item label="電話號碼" prop="teleNo">
                        <el-input
                            v-model="form.teleNo"
                            placeholder="輸入電話號碼"
                            style="width: 100%"
                            type="number"
                            :maxlength="8"
                            :minlength="8"
                        ></el-input> </el-form-item
                    ><el-form-item label="地址" prop="address">
                        <el-input
                            v-model="form.address"
                            placeholder="輸入地址"
                            style="width: 100%"
                        ></el-input> </el-form-item
                    ><el-form-item label="出生日期" prop="dob">
                        <el-date-picker
                            v-model="form.dob"
                            placeholder="輸入出生日期"
                            type="date"
                            style="width: 100%"
                        ></el-date-picker>
                    </el-form-item>
                </div>
                <div v-if="active == 2" class="mt-2">
                    <el-form-item label="薪金" prop="salary">
                        <el-input
                            v-model="form.salary"
                            placeholder="輸入薪金"
                            type="number"
                            style="width: 100%"
                        ></el-input> </el-form-item
                    ><el-form-item label="銀行" prop="bank">
                        <el-select
                            v-model="form.bank"
                            placeholder="輸入銀行"
                            style="width: 100%"
                            filterable
                            ><el-option
                                v-for="(_b, i) in bankList"
                                :key="i"
                                :value="_b.value"
                                :label="_b.label"
                            >
                            </el-option
                        ></el-select>
                    </el-form-item>
                    <el-form-item label="銀行帳戶" prop="bankaccount">
                        <el-input
                            v-model="form.bankaccount"
                            placeholder="輸入銀行帳戶"
                            style="width: 100%"
                        ></el-input>
                    </el-form-item>
                </div>

                <el-form-item>
                    <el-button
                        type="primary"
                        plain
                        @click="onCancel"
                        v-text="previous_text"
                    ></el-button>
                    <el-button
                        type="primary"
                        plain
                        @click="onSubmit('form')"
                        v-text="next_text"
                    ></el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>

<style>
.hasUpload > div {
    display: none;
}
</style>

<script>
import firebase from "firebase/compat/app";
import VueQr from "vue-qr";
export default {
    name: "Employees",
    components: {
        VueQr,
    },
    data() {
        return {
            table: [
                // {
                //     name: "陳大明",
                //     eng_name: "Chan Tai Man",
                //     position: "員工",
                //     deptName: "ABC部",
                //     teleNo: 59382958,
                // },
            ],
            circleUrl:
                "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
            dialogVisible: false,
            search: "",
            form: {
                fullname: "",
                eng_name: "",
                email: "",
                password: "",
                teleNo: "",
                address: "",
                dob: "",
                salary: "",
                bank: "",
                bankaccount: "",
            },
            editForm: {
                fullname: "",
                eng_name: "",
                teleNo: "",
                address: "",
                dob: "",
                salary: "",
                bank: "",
                bankaccount: "",
            },
            formRules: {
                fullname: [
                    { required: true, message: "請輸入全名", trigger: "blur" },
                ],
                eng_name: [
                    {
                        required: true,
                        message: "請輸入英文名",
                        trigger: "blur",
                    },
                ],
                password: [
                    { required: true, message: `請輸入密碼`, trigger: "blur" },
                ],
                email: [
                    { required: true, message: "請輸入郵箱", trigger: "blur" },
                    { type: "email", message: "請輸入郵箱", trigger: "blur" },
                ],
                teleNo: [
                    {
                        required: true,
                        message: `請輸入電話號碼`,
                        trigger: "blur",
                    },
                ],
                address: [
                    {
                        required: true,
                        message: `請輸入地址`,
                        trigger: "blur",
                    },
                ],
                dob: [
                    {
                        required: true,
                        message: `請輸入生日日期`,
                        trigger: `blur`,
                    },
                ],
                salary: [
                    {
                        required: true,
                        message: `請輸入薪金`,
                        trigger: `blur`,
                    },
                ],
                bank: [
                    {
                        required: true,
                        message: `請選擇銀行`,
                        trigger: `blur`,
                    },
                ],
                bankaccount: [
                    {
                        required: true,
                        message: `請輸銀行號碼`,
                        trigger: `blur`,
                    },
                ],
            },
            bankList: [],
            hasUpload: "",
            active: 0,
            page: 1,
            pageSize: 8,
            editDialog: false,
            empLoading: false,
            loading_count: 0,
        };
    },
    methods: {
        handleCurrentChange(c) {
            this.page = c;
        },
        onUpdateProfile() {
            this.loading_count++;
            const empID = this.editForm.empID;
            const dob = new Date(this.editForm.dob).toISOString().slice(0, 10);
            const updates = {
                name: this.editForm.fullname,
                eng_name: this.editForm.eng_name,
                address: this.editForm.address,
                bank: this.editForm.bank,
                bank_ac: this.editForm.bankaccount,
                salary: this.editForm.salary,
                tel: this.editForm.teleNo,
                dob: dob,
            };
            firebase
                .database()
                .ref(`users/${empID}`)
                .update(updates)
                .then(() => {
                    this.$message({
                        message: `修改成功`,
                        type: "success",
                    });
                })
                .then(() => {
                    this.getUsers();
                })
                .catch((err) => {
                    this.$message({
                        message: `發生錯誤 ${err.code}: ${err.message}`,
                        type: "error",
                    });
                })
                .finally(() => {
                    this.editDialog = false;
                    this.loading_count--;
                });
        },
        viewInfo(scope) {
            this.$router.push(`/employees/views/${scope.row.empID}`);
        },
        editInfo(scope) {
            const empID = scope.row.empID;
            this.editDialog = true;
            this.empLoading = true;
            const userRef = firebase.database().ref("users/" + empID);
            userRef
                .once("value")
                .then((snapshot) => {
                    return snapshot.val();
                })
                .then((v) => {
                    this.editForm.empID = empID;
                    this.editForm.fullname = v.name;
                    this.editForm.eng_name = v.eng_name;
                    this.editForm.bank = v.bank;
                    this.editForm.bankaccount = v.bank_ac;
                    this.editForm.address = v.address;
                    this.editForm.dob = v.dob;
                    this.editForm.salary = v.salary;
                    this.editForm.teleNo = v.tel;
                })
                .catch((err) => {
                    this.$message({
                        message: `${err.code}: ${err.message}`,
                        type: "error",
                    });
                })
                .finally(() => {
                    this.empLoading = false;
                });
        },
        onSuccess() {
            this.hasUpload = "hasUpload";
        },
        onRemove() {
            this.hasUpload = "";
        },
        onSubmit(form) {
            if (this.active >= 2) {
                this.$refs[form].validate((valid) => {
                    if (valid) {
                        /*
                        Handle form submit
                        */
                        this.loading_count++;
                        const dob = new Date(this.form.dob)
                            .toISOString()
                            .slice(0, 10);
                        const user = {
                            name: this.form.fullname,
                            eng_name: this.form.eng_name,
                            bank: this.form.bank,
                            bank_ac: this.form.bankaccount,
                            address: this.form.address,
                            dob: dob,
                            role: "user",
                            salary: this.form.salary,
                            tel: this.form.teleNo,
                            email: this.form.email,
                        };

                        // CREATE email and password
                        firebase
                            .auth()
                            .createUserWithEmailAndPassword(
                                this.form.email,
                                this.form.password
                            )
                            .then((userCredential) => {
                                var u = userCredential.user;
                                var uid = u.uid;
                                const userRef = firebase
                                    .database()
                                    .ref("users/" + uid);
                                userRef
                                    .update(user)
                                    .then(() => {
                                        firebase
                                            .firestore()
                                            .collection("users")
                                            .doc(uid)
                                            .set({
                                                Name: user.name,
                                                ID: uid,
                                                name_lower:
                                                    user.name.toLowerCase(),
                                                chats: [],
                                                profilePic:
                                                    "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
                                            })
                                            .then(() => {
                                                this.$message({
                                                    message: "成功新增員工",
                                                    type: "success",
                                                });
                                            })
                                            .catch((err) => {
                                                this.$message({
                                                    message: `發生錯誤 ${err.code}: ${err.message}`,
                                                    type: "error",
                                                });
                                            })
                                            .finally(() => {
                                                this.$router.go(
                                                    this.$router.currentRoute
                                                );
                                            });
                                    })
                                    .catch((err) => {
                                        this.$message({
                                            message: "發生錯誤: " + err.message,
                                            type: "error",
                                        });
                                    });
                            })
                            .catch((err) => {
                                this.$message({
                                    message: `${err.message}`,
                                    type: "danger",
                                });
                            })
                            .finally(() => {
                                this.loading_count--;
                            });
                    } else {
                        return false;
                    }
                });
            } else {
                this.active++;
            }
        },
        getUsers() {
            this.loading_count++;
            this.table = [];
            const userRef = firebase.database().ref("users");
            userRef
                .once("value")
                .then((snapshot) => {
                    return snapshot.val();
                })
                .then((value) => {
                    for (const o in value) {
                        const user = value[o];
                        if (user.role != "administrator") {
                            this.table.push({
                                name: user.name,
                                eng_name: user.eng_name,
                                email: user.email,
                                teleNo: user.tel,
                                empID: o,
                            });
                        }
                    }
                })
                .finally(() => {
                    this.loading_count--;
                });
        },
        onCancel() {
            if (this.active > 0) {
                this.active--;
            } else {
                this.dialogVisible = !this.dialogVisible;
            }
        },
        confirmDelete(scope) {
            const empID = scope.row.empID;
            firebase
                .database()
                .ref(`users/${empID}`)
                .remove()
                .then(() => {
                    this.$message({
                        message: `刪除員工成功`,
                        type: `success`,
                    });
                })
                .then(() => {
                    /* 處理 相關員工資料 */
                    const deptRef = firebase
                        .database()
                        .ref("company/departments");
                    deptRef
                        .once("value")
                        .then((snapshot) => snapshot.val())
                        .then((value) => {
                            const _value = Object.keys(value);
                            _value.forEach((_v) => {
                                if (value[_v].members.manager == empID) {
                                    firebase
                                        .database()
                                        .ref(
                                            "company/departments/" +
                                                _v +
                                                "/members"
                                        )
                                        .update({
                                            manager: "",
                                        });
                                }
                                if (
                                    value[_v].members.staff != null &&
                                    value[_v].members.staff.empID == 1
                                ) {
                                    firebase
                                        .database()
                                        .ref(
                                            "company/departments/" +
                                                _v +
                                                "/members/staff/" +
                                                empID
                                        )
                                        .remove();
                                }
                            });
                        });
                })
                .catch((err) => {
                    this.$message({
                        message: `發生錯誤 ${err.code}: ${err.message}`,
                        type: "error",
                    });
                })
                .finally(() => {
                    this.getUsers();
                    this.page = 1;
                });
        },
    },
    computed: {
        loading() {
            return this.loading_count != 0;
        },
        next_text() {
            if (this.active < 2) return "下一步";
            return "確定";
        },
        previous_text() {
            if (this.active > 0) return "上一步";
            return "取消";
        },
        pagedTableData() {
            return this.tableData.slice(
                this.pageSize * this.page - this.pageSize,
                this.pageSize * this.page
            );
        },
        total() {
            return this.tableData.length;
        },
        tableData() {
            return this.table.filter(
                (data) =>
                    !this.search ||
                    data.name
                        .toLowerCase()
                        .includes(this.search.toLowerCase()) ||
                    data.teleNo
                        .toLowerCase()
                        .includes(this.search.toLowerCase()) ||
                    data.eng_name
                        .toLowerCase()
                        .includes(this.search.toLowerCase())
            );
        },
    },
    mounted() {
        this.getUsers();
        this.loading_count++;
        const banksRef = firebase.database().ref("constants/banks");
        banksRef
            .once("value")
            .then((snapshot) => {
                return snapshot.val();
            })
            .then((value) => {
                for (const bank in value) {
                    this.bankList.push({
                        label: value[bank],
                        value: value[bank],
                    });
                }
            })
            .finally(() => {
                this.loading_count--;
            });
    },
};
</script>
