const firebaseConfig = {
  apiKey: "AIzaSyCSXjqxlrvP0WqK1nrVYKO8QIRqpY6HGus",
  authDomain: "ace-shop-44119.firebaseapp.com",
  databaseURL: "https://ace-shop-44119-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "ace-shop-44119",
  storageBucket: "ace-shop-44119.appspot.com",
  messagingSenderId: "392386739326",
  appId: "1:392386739326:web:063a707162edceab14ac2f",
  measurementId: "G-42LS3G96ZS"
};

//  溫馨提示：建議使用較淺的顏色
//  歡迎回來的背景顏色
const panelColor        = "#e8f2fa"

//  溫馨提示：建議使用較深的顏色
//  側邊欄的背景顏色
const sideBarColor      = "#000"

//        /||\
//       / || \
//      |------|
//      |  ||  |
//      |  ||  |
//      |  ||  |
//      |  ||  |
//       \ || /
//  不用理 \||/
//        /||\
//       / || \
export {
    firebaseConfig,
    panelColor,
    sideBarColor
}