<template>
    <div class="main">
        <el-card class="card-box" shadow="never" v-loading="loading">
            <div slot="header">
                <el-breadcrumb separator="/">
                    <el-breadcrumb-item to="/">主頁</el-breadcrumb-item>
                    <el-breadcrumb-item to="/employees"
                        >員工</el-breadcrumb-item
                    >
                    <el-breadcrumb-item>瀏覽</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
            <el-card shadow="never">
                <el-descriptions title="員工資訊" :column="3">
                    <el-descriptions-item label="全名">{{
                        name
                    }}</el-descriptions-item>
                    <el-descriptions-item label="英文名">{{
                        eng_name
                    }}</el-descriptions-item>
                    <el-descriptions-item label="郵箱">{{
                        email
                    }}</el-descriptions-item>
                    <el-descriptions-item label="電話號碼">{{
                        tel
                    }}</el-descriptions-item>
                    <el-descriptions-item label="地址">{{
                        address
                    }}</el-descriptions-item>
                    <el-descriptions-item label="出生日期">{{
                        dob
                    }}</el-descriptions-item>
                </el-descriptions>
            </el-card>

            <el-card shadow="never" class="mt-1">
                <el-descriptions title="付款" :column="2">
                    <el-descriptions-item label="銀行">{{
                        bank
                    }}</el-descriptions-item>
                    <el-descriptions-item label="銀行帳戶">{{
                        bank_ac
                    }}</el-descriptions-item>
                </el-descriptions>
            </el-card>

            <el-card shadow="never" class="mt-1">
                <el-descriptions title="部門" :column="1">
                    <el-descriptions-item
                        v-for="(dept, i) in depts"
                        :key="i"
                        :label="dept.label"
                        ><el-tag size="small">{{ dept.value }}</el-tag></el-descriptions-item
                    >
                </el-descriptions>
            </el-card>
        </el-card>
    </div>
</template>
<script>
import firebase from "firebase/compat/app";

export default {
    name: "",
    data() {
        return {
            name: null,
            eng_name: null,
            email: null,
            tel: null,
            address: null,
            dob: null,
            bank: null,
            bank_ac: null,

            loading_count: 0,

            depts: [],
        };
    },
    mounted() {
        this.loading_count++;
        const empID = this.$route.params.id;
        const userRef = firebase.database().ref("users/" + empID);
        userRef
            .once("value")
            .then((snapshot) => snapshot.val())
            .then((value) => {
                this.name = value.name;
                this.eng_name = value.eng_name;
                this.email = value.email;
                this.tel = value.tel;
                this.address = value.address;
                this.dob = value.dob;
                this.bank = value.bank;
                this.bank_ac = value.bank_ac;
            })
            .finally(() => {
                this.loading_count--;
            });
        this.loading_count++
        const deptRef = firebase.database().ref("company/departments");
        deptRef
            .once("value")
            .then((snapshot) => snapshot.val())
            .then((value) => {
                const v = Object.values(value);
                v.forEach((dept) => {
                    if (
                        dept.members.manager == empID ||
                        dept.members.staff[empID] == 1
                    ) {
                        const isManager = dept.members.manager == empID;
                        this.depts.push({
                            label: dept.name,
                            value: isManager ? "主管" : "員工",
                        });
                    }
                });
            }).finally(()=>{
                this.loading_count--
            })
    },
    computed: {
        loading() {
            return this.loading_count != 0;
        },
    },
};
</script>
