<template>
    <el-menu
        default-active="1"
        class="el-menu-vertical"
        style="height: 100%"
        :collapse="isCollapse"
        router
        :background-color="sideBarColor"
        text-color="#fff"
        active-text-color="#fff"
    >
        <el-menu-item index="/" class="sidebar-header">
            <span slot="title" style="border-bottom: 2px solid white !important"
                >後台管理</span
            >
        </el-menu-item>
        <el-menu-item index="/company">
            <i class="el-icon-office-building"></i>
            <span slot="title">公司</span>
        </el-menu-item>
        <el-menu-item index="/departments">
            <i class="el-icon-s-cooperation"></i>
            <span slot="title">部門</span>
        </el-menu-item>
        <el-menu-item index="/employees">
            <i class="el-icon-user"></i>
            <span slot="title">員工</span>
        </el-menu-item>
        <el-menu-item index="/attendance">
            <i class="el-icon-alarm-clock"></i>
            <span slot="title">通勤</span>
        </el-menu-item>
        <el-menu-item index="/shift">
            <i class="el-icon-office-building"></i>
            <span slot="title">更表</span>
        </el-menu-item>
        <el-menu-item index="/notification">
            <i class="el-icon-bell"></i>
            <span slot="title">通知</span>
        </el-menu-item>
        <el-menu-item index="/assign-work">
            <i class="el-icon-document"></i>
            <span slot="title">工作分配</span>
        </el-menu-item>
        <el-submenu index="/review">
            <template slot="title">
                <i class="el-icon-s-claim"></i>
                <span>審核</span>
            </template>
            <el-menu-item index="/leave">請假</el-menu-item>
            <el-menu-item index="/procurement">採購</el-menu-item>
            <el-menu-item index="/claim">報銷</el-menu-item>
            <el-menu-item index="/ot">超時工作</el-menu-item>
            <el-menu-item index="adjustment">補卡</el-menu-item>
        </el-submenu>
        <el-menu-item index="/setting">
            <i class="el-icon-s-operation"></i>
            <span slot="title">設定</span>
        </el-menu-item>
        <el-menu-item index="/logout" @click="onLogout">
            <i class="el-icon-house"></i>
            <span slot="title">登出</span>
        </el-menu-item>
        <!--el-menu-item @click="menuCollapse">
      <i :class="isCollapse ? 'el-icon-d-arrow-right' : 'el-icon-d-arrow-left'"></i>
      <span slot="title">折疊面板</span>
    </el-menu-item-->
    </el-menu>
</template>

<style>
.el-menu-item i,
.el-submenu__title i {
    color: #fff !important;
}
.el-menu-item,
.el-submenu__title {
    color: #fff !important;
    font-weight: 500;
}
.el-menu-item.is-active {
    color: #fff !important;
    font-weight: 500;
}
.el-menu-vertical:not(.el-menu--collapse) {
    width: 250px;
    min-height: 400px;
}

.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover {
    border-color: #409eff;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #fff;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}

.avatar {
    width: 178px;
    height: 178px;
    display: block;
}

.sidebar-header {
    height: initial !important;
    color: #fff !important;
    font-size: 1.1em !important;
    padding-top: 20px !important;
    padding-bottom: 20px !important;
    font-weight: 700;
    text-align: center;
}
.sidebar-header.is-active {
    color: #fff !important;
    border-left: 0px;
    font-size: 1.1em;
    font-weight: 700;
}
</style>

<script>
import { sideBarColor } from "@/config";
import firebase from "firebase/compat/app";
export default {
    name: "Sidebar",
    data() {
        return {
            isCollapse: false,
            sideBarColor,
        };
    },
    created() {
        if (localStorage.getItem("isCollapse") != null) {
            this.isCollapse = localStorage.getItem("isCollapse") == "true";
        }
    },
    methods: {
        menuCollapse() {
            this.isCollapse = !this.isCollapse;
            localStorage.setItem("isCollapse", this.isCollapse);
            this.$router.go(this.$router.currentRoute);
        },
        onLogout() {
            firebase
                .auth()
                .signOut()
                .then(() => {
                    this.$message({
                        message: "登出成功",
                        type: "success",
                    });
                });
        },
    },
};
</script>
