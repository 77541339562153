// VUE ROUTER
import VueRouter from "vue-router";

// VIEWS
import Home from '@/views/Home';
import Company from '@/views/Company';
import Login from '@/views/Login';
import Departments from '@/views/Departments'
import Employees from '@/views/Employees';
import Attendance from '@/views/Attendance'
import Shift from '@/views/Shift'
import Notification from '@/views/Notification';
import WorkAssign from '@/views/WorkAssign'
import Procurement from '@/views/Procurement';
import Claim from '@/views/Claim';
import Leave from '@/views/Leave';
import Setting from '@/views/Setting'
import Departments_Edit from '@/views/Departments_Edit';
import Employees_View from '@/views/Employees_View';
import OT_View from '@/views/OT';
import Adjustment from '@/views/Adjustment'  // 補卡

const routes = [
    { path: '/', component: Home, name: "Home"},
    { path: '/company', component: Company, name: "Company" },
    { path: '/login', component: Login, name: "Login" },
    { path: '/departments', component: Departments, name: "Departments" },
    { path: '/employees', component: Employees, name: "Employees" },
    { path: '/attendance', component: Attendance, name: "Attendance" },
    { path: '/shift', component: Shift, name: "Shift" },
    { path: '/notification', component: Notification, name: "Notification" },
    { path: '/assign-work', component: WorkAssign, name: 'Work Assign'},
    { path: '/claim', component: Claim, name: 'Claim' },
    { path: '/leave', component: Leave, name: "Leave" },
    { path: '/ot', component: OT_View, name: 'OT'},
    { path: '/procurement', component: Procurement, name: "Procurement" },
    { path: '/setting', component: Setting, name: 'Setting'},
    { path: '/departments/edit/:id', component: Departments_Edit, name: "Departments_Edit" },
    { path: '/employees/views/:id', component: Employees_View, name: "Employees_View"},
    { path: '/adjustment', component: Adjustment, name: 'Adjustment' }
]

const router = new VueRouter({
    mode: 'history',
    routes
})

export {router, VueRouter};