<template>
    <div class="main">
        <el-card shadow='never' class='card-box' v-loading='loading'>
            <div slot='header'>
                <el-breadcrumb separator='/'>
                    <el-breadcrumb-item to='/'>主頁</el-breadcrumb-item>
                    <el-breadcrumb-item to='/departments'>部門</el-breadcrumb-item>
                    <el-breadcrumb-item>編輯</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
            <el-form ref='form' label-width='80px' v-model="form" :rules='formRules' >
                <el-form-item prop='name' label="名字">
                    <el-input type='text' v-model="form.name"></el-input>
                </el-form-item>
                <el-form-item prop='manager' label='主管'>
                    <el-select v-model="form.manager" filterable>
                        <el-option v-for="(user, i) in userList" :key="i" :label='user.label' :value='user.key'></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item prop='staff' label='員工'>
                    <el-transfer  style="width: 100%" filterable filter-placeholder='輸入關鍵字' v-model='form.staff' :data='userList' :titles="['員工', this.form.name == null ? ' ' : this.form.name]">
                    </el-transfer>
                </el-form-item>
                <el-form-item>
                    <el-button @click="onUpdate()" type='primary' plain>更新</el-button>
                </el-form-item>
            </el-form>
    </el-card>
    </div>
</template>
<style>
.el-transfer-panel{
    width: 40%!important;

}
.el-transfer-panel__body{
    height: 350px!important;
}
div.el-checkbox-group.el-transfer-panel__list.is-filterable{
    height: 300px!important;
}
</style>
<script>
import firebase from 'firebase/compat/app'

export default {
    name: 'Departments_Edit',
    data(){
        return {
            form: {
                deptID: null,
                name: null,
                manager: null,
                staff: []
            },
            formRules: {
                
            },
            userList: [],
            loading_count: 0
        }
    },
    mounted(){
    this.loading_count++
        this.form.deptID = this.$route.params.id;
        firebase.database().ref('users').once('value').then(snapshot=>snapshot.val())
        .then(v=>{
            Object.keys(v).forEach(userID=>{
                const user = v[userID]
                if(user.role == 'user'){
                    this.userList.push({
                        label: user.name,
                        key: userID,
                    })
                }
            })
        }).then(()=>{
            firebase.database().ref('company/departments/' + this.form.deptID).once('value').then(s=>s.val())
            .then(value=>{
                this.form.name = value.name
                this.userList.forEach(user=>{
                    if(user.key == value.members.manager)
                    { 
                        this.form.manager = value.members.manager
                    }
                })
                const staff = value.members.staff
                this.form.staff = Object.keys(staff)
            }).finally(()=>{
                this.loading_count--
            })
        })

    },
    computed:{
        loading(){
            return this.loading_count != 0
        }
    },
    methods: {
        onUpdate(){
            const dept = {
                name: this.form.name,
                members: {
                    manager: this.form.manager,
                    staff: {

                    }
                }
            }
            this.form.staff.forEach(staffID=>{
                dept.members.staff[staffID] = 1
            })
            this.loading_count++
            firebase.database().ref('company/departments/' + this.form.deptID).update(dept)
            .then(()=>{
                this.$message({
                    message: `編輯部門成功`,
                    type: 'success'
                })
                this.$router.push('/departments')
            }).finally(()=>{
                this.loading_count--
            })
        }
    }
}
</script>