<template>
    <div class="main">
        <el-dialog
            :visible.sync="dialogVisible"
            width="25%"
            title="編輯通勤紀錄"
            top="10px"
        >
            <el-form label-width="80px" ref="editForm" v-model="editForm">
                <el-form-item label="員工">
                    <el-input type="text" v-model="editForm.name" disabled>
                    </el-input>
                </el-form-item>
                <el-form-item label="日期">
                    <el-date-picker v-model="editForm.date" style="width: 100%">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="上班">
                    <el-time-picker
                        v-model="editForm.online"
                        style="width: 100%"
                    >
                    </el-time-picker>
                </el-form-item>
                <el-form-item label="上班狀態">
                    <el-select
                        v-model="editForm.workStatus"
                        style="width: 100%"
                        clearable
                    >
                        <el-option label="正常" value="正常"></el-option>
                        <el-option label="遲到" value="遲到"></el-option>
                        <el-option label="曠工" value="曠工"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="下班">
                    <el-time-picker
                        v-model="editForm.offline"
                        style="width: 100%"
                    >
                    </el-time-picker>
                </el-form-item>
                <el-form-item label="下班狀態">
                    <el-select
                        v-model="editForm.leaveStatus"
                        style="width: 100%"
                        clearable
                    >
                        <el-option label="早退" value="早退"></el-option>
                        <el-option label="正常" value="正常"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="updateRecord" plain
                        >更新</el-button
                    >
                    <el-button
                        type="info"
                        @click="dialogVisible = !dialogVisible"
                        plain
                        >關閉</el-button
                    >
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-card
            shadow="never"
            style="width: 100vh !important"
            class="card-box"
            v-loading="loading"
        >
            <div slot="header">
                <el-breadcrumb separator="/">
                    <el-breadcrumb-item to="/">主頁</el-breadcrumb-item>
                    <el-breadcrumb-item>通勤</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
            <el-card shadow="never">
                <div slot="header">篩選</div>
                <el-form label-width="50px">
                    <el-form-item label="日期"
                        ><el-button
                            plain
                            icon="el-icon-caret-left"
                            @click="
                                filter.date = new Date(filter.date).setDate(
                                    new Date(filter.date).getDate() - 1
                                )
                            "
                        ></el-button>
                        <el-date-picker
                            v-model="filter.date"
                            type="date"
                            placeholder="YYYY-MM-DD"
                        ></el-date-picker>
                        <el-button
                            plain
                            icon="el-icon-caret-right"
                            @click="
                                filter.date = new Date(filter.date).setDate(
                                    new Date(filter.date).getDate() + 1
                                )
                            "
                        ></el-button>
                        <el-button
                            @click="
                                filter.date =
                                    new Date().getTime() - 1000 * 60 * 24 * 60
                            "
                            >昨天</el-button
                        >
                        <el-button @click="filter.date = new Date().getTime()"
                            >今天</el-button
                        ></el-form-item
                    >
                    <el-form-item label="員工">
                        <el-select
                            v-model="filter.username"
                            clearable
                            placeholder="員工"
                            filterable
                        >
                            <el-option
                                v-for="(user, i) in userList"
                                :key="i"
                                :label="user.label"
                                :value="user.value"
                            >
                            </el-option>
                        </el-select>

                        <!-- <vue-json-to-csv
                            :json-data="csv.data"
                            :labels="csv.labels"
                            :csv-title="csv.title"
                        > -->
                        <el-button
                            type="primary"
                            plain
                            style="margin-left: 1em"
                            @click="exportCSV"
                            >導出 CSV</el-button
                        >
                        <!-- </vue-json-to-csv> -->
                    </el-form-item>
                </el-form>
            </el-card>
            <div class="mt-1">
                <el-table
                    :data="tableData"
                    height="550"
                    :default-sort="{ prop: 'date', order: 'descending' }"
                >
                    <el-table-column label="員工">
                        <template slot-scope="scope">
                            <el-row>
                                <el-col :span="8"
                                    ><el-avatar
                                        :size="40"
                                        :src="circleUrl"
                                    ></el-avatar
                                ></el-col>
                                <el-col :span="16"
                                    ><div>{{ scope.row.name }}</div>
                                    <div>{{ scope.row.eng_name }}</div></el-col
                                >
                            </el-row>
                        </template>
                    </el-table-column>
                    <el-table-column label="日期" prop="date" sortable>
                    </el-table-column>
                    <el-table-column label="上班" prop="online" sortable>
                    </el-table-column>
                    <el-table-column label="狀態" prop="workStatus" sortable>
                        <template slot-scope="scope">
                            <span v-if="scope.row.workStatus == '補卡'">
                                <el-tag type="success">{{
                                    scope.row.workStatus
                                }}</el-tag>
                            </span>

                            <span v-else-if="scope.row.workStatus == '正常'">
                                <el-tag type="success">{{
                                    scope.row.workStatus
                                }}</el-tag>
                            </span>
                            <span v-else-if="scope.row.workStatus == '遲到'">
                                <el-tag type="warning">{{
                                    scope.row.workStatus
                                }}</el-tag>
                            </span>
                            <span v-else-if="scope.row.workStatus == '曠工'">
                                <el-tag type="danger">{{
                                    scope.row.workStatus
                                }}</el-tag>
                            </span>
                            <span v-else>
                                <el-tag type="info">{{
                                    scope.row.workStatus
                                }}</el-tag>
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column label="下班" prop="offline" sortable>
                    </el-table-column>
                    <el-table-column label="狀態" prop="leaveStatus" sortable>
                        <template slot-scope="scope">
                            <span v-if="scope.row.leaveStatus == '正常'">
                                <el-tag type="success">{{
                                    scope.row.leaveStatus
                                }}</el-tag>
                            </span>
                            <span v-if="scope.row.leaveStatus == '下班'">
                                <el-tag type="success">{{
                                    scope.row.leaveStatus
                                }}</el-tag>
                            </span>
                            <span v-if="scope.row.leaveStatus == '早退'">
                                <el-tag type="warning">{{
                                    scope.row.leaveStatus
                                }}</el-tag>
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作">
                        <template slot-scope="scope">
                            <el-button
                                circle
                                plain
                                icon="el-icon-edit-outline"
                                @click="handleClick(scope)"
                                type="primary"
                            ></el-button>
                            <el-popconfirm
                                cancel-button-text="不用了"
                                confirm-button-text="好的"
                                title="確定刪除此通勤紀錄嗎？"
                                @confirm="deleteRecord(scope)"
                            >
                                <el-button
                                    circle
                                    icon="el-icon-delete"
                                    plain
                                    type="danger"
                                    style="margin-left: 1em"
                                    slot="reference"
                                >
                                </el-button>
                            </el-popconfirm>
                        </template>
                    </el-table-column>
                </el-table>
                <table id="csv" v-show="false" style="margin-top: 100%">
                    <tbody>
                        <tr>
                            <td colspan="37" rowspan="4" id="title">
                                2022-07 通勤報表
                            </td>
                        </tr>
                        <tr>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                        </tr>
                        <tr id="columns">
                            <td rowspan="2" colspan="1">姓名</td>
                            <td colspan="3" rowspan="1">請假</td>
                            <td colspan="2" rowspan="1">備註</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>有薪假</td>
                            <td>事假</td>
                            <td>病假</td>
                            <td>遲到</td>
                            <td>曠工</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </el-card>
    </div>
</template>

<script>
import firebase from "firebase/compat/app";
import $ from "jquery";

import "table2excel";
const Table2Excel = window.Table2Excel;

export default {
    name: "Attendance",

    data() {
        return {
            filter: {
                username: "",
                date: "",
            },
            table: [],
            circleUrl:
                "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
            loading_count: 0,
            userList: [],

            dialogVisible: false,

            csv: {
                title: "",
                labels: {
                    name: { title: "姓名" },
                    late: { title: "遲到" },
                    absent: { title: "曠工" },
                    eventLeave: { title: "事假" },
                    sickLeave: { title: "病假" },
                    paidLeave: { title: "年假" },
                },
                data: [],
            },

            /** 編輯 通勤紀錄 */
            editForm: {
                name: null,
                date: null,
                workStatus: null,
                leaveStatus: null,
                workTimestamp: null,
                leaveTimestamp: null,
            },
        };
    },
    methods: {
        exportCSV() {
            var table2excel = new Table2Excel();
            table2excel.export(document.querySelectorAll("table#csv"));
        },
        getCSVJson() {
            var d = new Date();
            var _firstDay = new Date(d.getFullYear(), d.getMonth() + 1, 1);
            var _lastday = new Date(
                _firstDay.setDate(_firstDay.getDate() - 1)
            ).getDate();
            for (let i = 1; i <= _lastday; i++) {
                $("tr#columns").append(`<td rowspan='2'>${i}</td>`);
            }

            /** Setup labels */

            var lastday = new Date(
                d.getFullYear(),
                d.getMonth() + 1,
                0
            ).getDate();

            for (let i = 1; i <= lastday; i++) {
                this.csv.labels[`_${i}_`] = {
                    title: i,
                };
            }
            // Name
            this.loading_count++;
            this.csv.data = [];

            firebase
                .database()
                .ref("users")
                .once("value")
                .then((snapshot) => snapshot.val())
                .then((value) => {
                    Object.keys(value).forEach((userID) => {
                        const userObj = value[userID];
                        var _absent = 0;
                        var _late = 0;
                        var _sickLeave = 0;
                        var _eventLeave = 0;
                        var _paidLeave = 0;
                        var dataObj = {};

                        if (
                            userObj.role != "administrator" &&
                            userObj.shiftID != null
                        ) {
                            dataObj["name"] = userObj.name;
                            const shiftID = userObj.shiftID;
                            firebase
                                .database()
                                .ref("company/shifts/shifts/" + shiftID)
                                .once("value")
                                .then((_snapshot) => _snapshot.val())
                                .then(() => {
                                    for (let i = 1; i <= lastday; i++) {
                                        if (userObj.attendance != null) {
                                            var r =
                                                userObj.attendance.record[
                                                    `${this.formatDate(
                                                        new Date().setDate(i)
                                                    )}`
                                                ];
                                            if (r == void 0) {
                                                if (
                                                    new Date(
                                                        new Date().setDate(i)
                                                    ).getDay() == 0 ||
                                                    new Date(
                                                        new Date().setDate(i)
                                                    ).getDay() == 6
                                                ) {
                                                    dataObj[`_${i}_`] = "休息";
                                                } else {
                                                    dataObj[`_${i}_`] = "正常";
                                                }
                                            } else {
                                                // const leaves = [
                                                //     "病假",
                                                //     "事假",
                                                //     "年假",
                                                //     "產假",
                                                //     "陪產假",
                                                //     "婚假",
                                                //     "喪假",
                                                //     "哺乳假",
                                                //     "其他",
                                                // ];
                                                var rID = Object.keys(r)[0];
                                                dataObj[`_${i}_`] =
                                                    r[rID].workStat;
                                                if (r[rID].workStat == "曠工")
                                                    _absent++;
                                                else if (
                                                    r[rID].workStat == "遲到"
                                                )
                                                    _late++;
                                                else {
                                                    if (
                                                        r[rID].workStat ===
                                                        "病假"
                                                    ) {
                                                        _sickLeave++;
                                                    } else if (
                                                        r[rID].workStat ===
                                                        "事假"
                                                    ) {
                                                        _eventLeave++;
                                                    } else {
                                                        _paidLeave++;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    this.csv.data.push(dataObj);

                                    $("table#csv>tbody").append(`
                                    <tr id='${dataObj.name}'>
                                        <td>${dataObj.name}</td>
                                        <td>${_paidLeave}</td>
                                        <td>${_eventLeave}</td>
                                        <td>${_sickLeave}</td>
                                        <td>${_late}</td>
                                        <td>${_absent}</td>
                                    </tr>
                                    `);
                                    for (let i = 1; i <= _lastday; i++) {
                                        $(
                                            `table>tbody>tr[id='${dataObj.name}']`
                                        ).append(
                                            `<td>${dataObj["_" + i + "_"]}</td>`
                                        );
                                    }

                                    $("table#csv>tbody").append(`</tr>`);
                                });
                        }
                    });
                })
                .finally(() => {
                    this.loading_count--;
                });
        },
        updateRecord() {
            const uid = this.editForm.uid;
            const date = this.editForm.date;
            const id = this.editForm.id;
            this.loading_count++;
            firebase
                .database()
                .ref(`users/${uid}/attendance/record/${date}/${id}`)
                .update({
                    leaveStat: this.editForm.leaveStatus,
                    workStat: this.editForm.workStatus,
                    workTimestamp: this.editForm.online,
                    leaveTimestamp: this.editForm.offline,
                })
                .then(() => {
                    this.$message({
                        message: `更新成功`,
                        type: "success",
                    });
                })
                .catch((err) => {
                    this.$message({
                        message: `發生錯誤 ${err.code} : ${err.message}`,
                        type: "error",
                    });
                })
                .finally(() => {
                    this.dialogVisible = false;
                    this.loading_count--;
                    this.getAttendance();
                });
        },
        deleteRecord(scope) {
            const uid = scope.row.uid;
            const date = scope.row.date;
            const id = scope.row.id;
            this.loading_count++;
            firebase
                .database()
                .ref(`users/${uid}/attendance/record/${date}/${id}`)
                .remove()
                .then(() => {
                    this.loading_count--;
                    this.$message({
                        message: `成功刪除該通勤紀錄`,
                        type: "success",
                    });
                })
                .catch((err) => {
                    console.error(err);
                    this.$message({
                        messages: `發生錯誤 ${err.code} : ${err.message}`,
                        type: "error",
                    });
                })
                .finally(() => {
                    this.getAttendance();
                });
        },
        handleCurrentChange(page) {
            this.page = page;
        },
        handleClick(scope) {
            this.dialogVisible = true;
            const name = scope.row.name;
            const date = scope.row.date;
            const onlineTimestamp = scope.row.workTimestamp;
            const offlineTimestamp = scope.row.leaveTimestamp;
            const workStatus = scope.row.workStatus;
            const leaveStatus = scope.row.leaveStatus;
            const uid = scope.row.uid;
            const id = scope.row.id;

            this.editForm = {
                id: id,
                uid: uid,
                name: name,
                date: date,
                online: onlineTimestamp != null ? onlineTimestamp : "",
                offline: offlineTimestamp != null ? offlineTimestamp : "",
                workStatus: workStatus,
                leaveStatus: leaveStatus,
            };
        },
        /* Get Attendance Info */
        getAttendance() {
            this.loading_count++;
            /** reload table */
            this.table = [];
            const usersRef = firebase.database().ref("users");
            usersRef
                .once("value")
                .then((snapshot) => snapshot.val())
                .then((value) => {
                    const users = Object.keys(value);
                    users.forEach((uid) => {
                        const user = value[uid];
                        const shiftID = !user.shiftID ? null : user.shiftID;
                        const today =
                            new Date().getDay() == 0 ? 7 : new Date().getDay();
                        if (shiftID != null) {
                            firebase
                                .database()
                                .ref("company/shifts/shifts/" + shiftID)
                                .once("value")
                                .then((snapshot) => snapshot.val())
                                .then((value) => {
                                    if (value != null && value[today].enabled) {
                                        if (user.attendance != null) {
                                            const records =
                                                user.attendance.record;
                                            Object.keys(records).forEach(
                                                (date) => {
                                                    const temp = records[date];
                                                    Object.keys(temp).forEach(
                                                        (tmp) => {
                                                            this.table.push({
                                                                uid: uid,
                                                                name: user.name,
                                                                eng_name:
                                                                    user.eng_name,
                                                                date: date,
                                                                online: this.formatTime(
                                                                    temp[tmp]
                                                                        .workTimestamp
                                                                ),
                                                                offline:
                                                                    this.formatTime(
                                                                        temp[
                                                                            tmp
                                                                        ]
                                                                            .leaveTimestamp
                                                                    ),
                                                                workTimestamp:
                                                                    temp[tmp]
                                                                        .workTimestamp,
                                                                leaveTimestamp:
                                                                    temp[tmp]
                                                                        .leaveTimestamp,
                                                                workStatus:
                                                                    temp[tmp]
                                                                        .workStat,
                                                                leaveStatus:
                                                                    temp[tmp]
                                                                        .leaveStat,
                                                                id: tmp,
                                                            });
                                                        }
                                                    );
                                                }
                                            );
                                        }
                                    }
                                    if (value != null) {
                                        for (
                                            let i = 1;
                                            i <= new Date().getDate();
                                            i++
                                        ) {
                                            var year = new Date().getFullYear();
                                            var month =
                                                new Date().getMonth() + 1;
                                            var date = new Date(
                                                `${year}-${month}-${i}`
                                            );
                                            var formattedDate = this.formatDate(
                                                date.getTime()
                                            );
                                            const day =
                                                date.getDay() == 0
                                                    ? 7
                                                    : date.getDay();
                                            if (value[day].enabled == true) {
                                                if (user.attendance != null) {
                                                    if (
                                                        user.attendance.record[
                                                            formattedDate
                                                        ] == null
                                                    ) {
                                                        firebase
                                                            .database()
                                                            .ref(
                                                                "users/" +
                                                                    uid +
                                                                    "/attendance/record/" +
                                                                    formattedDate
                                                            )
                                                            .push({
                                                                leaveStat:
                                                                    "曠工",
                                                                workStat:
                                                                    "曠工",
                                                            })
                                                            .then(() => {
                                                                this.getAttendance();
                                                            });
                                                    }
                                                } else {
                                                    firebase
                                                        .database()
                                                        .ref(
                                                            "users/" +
                                                                uid +
                                                                "/attendance/record/" +
                                                                formattedDate
                                                        )
                                                        .push({
                                                            leaveStat: "曠工",
                                                            workStat: "曠工",
                                                        });
                                                }
                                            }
                                        }
                                    }
                                });
                        }
                    });
                })
                .catch((err) => {
                    this.$message({
                        message: `發生錯誤 ${err.code}: ${err.message}`,
                        type: "error",
                    });
                })
                .finally(() => {
                    this.loading_count--;
                });
        },
        /* format date */
        formatDate: (d) => {
            var temp_date = new Date(d);
            var year = temp_date.getFullYear();
            var month = temp_date.getMonth() + 1;
            var date = temp_date.getDate();
            var format = (n) => (n < 10 ? `0${n}` : n);
            return `${format(year)}-${format(month)}-${format(date)}`;
        },
        /* format time */
        formatTime: (t) => {
            var temp_date = new Date(t);
            var toString = temp_date.toString();
            var time = toString.split(" ")[4];
            return time;
        },
        /* get all users for the filter selection */
        getUserList() {
            this.userList = []; // delete all users from the list
            this.loading_count++;
            const usersRef = firebase.database().ref("users");
            usersRef
                .once("value")
                .then((snapshot) => snapshot.val())
                .then((value) => {
                    Object.keys(value).forEach((id) => {
                        const user = value[id];
                        if (user.role != "administrator") {
                            this.userList.push({
                                label: user.name,
                                value: user.name,
                            });
                        }
                    });
                })
                .catch((err) => {
                    this.message({
                        message: `發生錯誤 無法獲取員工資訊 ${err.code} : ${err.message}`,
                        type: "error",
                    });
                })
                .finally(() => {
                    this.loading_count--;
                });
        },
    },
    mounted() {
        this.getAttendance();
        this.getUserList();
        this.filter.date = this.formatDate(new Date());
        this.filter.username = null;
        this.getCSVJson();
    },
    computed: {
        tableData() {
            return this.table.filter((data) => {
                var a =
                    !this.filter.date ||
                    this.formatDate(this.filter.date) == data.date;
                var b =
                    !this.filter.username || data.name == this.filter.username;
                return a && b;
            });
        },
        loading() {
            return this.loading_count != 0;
        },
    },
};
</script>
