<template>
    <div class="main">
        <el-dialog :visible.sync="dialogVisible" title="新增更表" top="2em">
            <el-form>
                <el-form-item label="更表名稱" label-width="80px">
                    <el-input type="text" v-model="shiftForm.name"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-table ref="shiftForm" :data="shiftForm.data">
                        <el-table-column label="星期">
                            <template slot-scope="scope">
                                <el-checkbox
                                    v-model="scope.row.enabled"
                                    :label="scope.row.name"
                                ></el-checkbox>
                            </template>
                        </el-table-column>
                        <el-table-column label="上班">
                            <template slot-scope="scope">
                                <el-time-picker
                                    value-format="HH:mm"
                                    format="HH:mm"
                                    v-model="scope.row.start"
                                ></el-time-picker>
                            </template>
                        </el-table-column>
                        <el-table-column label="下班">
                            <template slot-scope="scope">
                                <el-time-picker
                                    value-format="HH:mm"
                                    format="HH:mm"
                                    v-model="scope.row.end"
                                ></el-time-picker>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        plain
                        icon="el-icon-edit"
                        @click="onAdd"
                        >添加</el-button
                    >
                    <el-button
                        type="info"
                        plain
                        @click="dialogVisible = !dialogVisible"
                        >關閉</el-button
                    >
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog
            :visible.sync="editDialog"
            title="設定員工班次"
            top="2em"
            width="30%"
        >
            <el-form label-width="80px">
                <el-form-item label="員工">
                    <el-input
                        type="text"
                        v-model="editForm.name"
                        disabled
                    ></el-input>
                </el-form-item>
                <el-form-item label="班次">
                    <el-select v-model="editForm.shiftID" filterable>
                        <el-option
                            v-for="(shift, i) in shifts"
                            :key="i"
                            :label="shift.label"
                            :value="shift.value"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        plain
                        icon="el-icon-edit"
                        @click="onSetting"
                        >設定</el-button
                    >
                    <el-button
                        type="info"
                        plain
                        @click="editDialog = !editDialog"
                        >關閉</el-button
                    >
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-card class="card-box" shadow="never" v-loading="loading">
            <div slot="header" style="">
                <el-breadcrumb separator="/">
                    <el-breadcrumb-item to="/">主頁</el-breadcrumb-item>
                    <el-breadcrumb-item>更表</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
            <el-row>
                <el-col :span="12">
                    <el-table :data="pagedTableData">
                        <el-table-column
                            label="員工"
                            sortable
                            :sort-method="sortByEngName"
                        >
                            <template slot-scope="scope">
                                <el-row>
                                    <el-col :span="8"
                                        ><el-avatar :size="40"></el-avatar
                                    ></el-col>
                                    <el-col :span="16"
                                        ><div>{{ scope.row.name }}</div>
                                        <div>
                                            {{ scope.row.eng_name }}
                                        </div></el-col
                                    >
                                </el-row>
                            </template>
                        </el-table-column>
                        <el-table-column label="班次" prop="shift" sortable>
                            <template slot-scope="scope">
                                <el-tag
                                    type="primary"
                                    v-if="scope.row.shift != ''"
                                    >{{ scope.row.shift }}</el-tag
                                >
                            </template>
                        </el-table-column>
                        <el-table-column label="操作">
                            <template slot="header" slot-scope="scope">
                                <el-input
                                    type="text"
                                    placeholder="搜尋關鍵字..."
                                    v-model="search"
                                    clearable
                                    :id="scope"
                                ></el-input>
                            </template>
                            <template slot-scope="scope">
                                <el-button
                                    @click="editShift(scope.row.empID)"
                                    icon="el-icon-edit-outline"
                                    circle
                                    plain
                                    type="primary"
                                ></el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div class="mt-1">
                        <el-pagination
                            @current-change="handleCurrentChange"
                            :current-page="page"
                            :page-size="pageSize"
                            layout="total, prev, pager, next, jumper"
                            :total="total"
                        >
                        </el-pagination>
                    </div>
                </el-col>

                <el-col :span="12">
                    <el-row>
                        <el-col :span="24">
                            <el-select
                                v-model="shiftID"
                                style="width: 85%"
                                placeholder="請選擇更表..."
                                filterable
                                clearable
                                @change="showShiftInfo"
                            >
                                <el-option
                                    v-for="(shift, i) in shifts"
                                    :key="i"
                                    :label="shift.label"
                                    :value="shift.value"
                                >
                                </el-option>
                            </el-select>
                            <el-popconfirm
                                :title="`確定刪除此更表嗎？`"
                                cancel-button-text="取消"
                                confirm-button-text="確定"
                                @confirm="deleteShift"
                            >
                                <el-button
                                    slot="reference"
                                    circle
                                    icon="el-icon-delete"
                                    plain
                                    :disabled="shiftID == null"
                                    style="margin-left: 1em"
                                ></el-button
                            ></el-popconfirm>
                            <el-button
                                circle
                                style="margin-left: 1em"
                                icon="el-icon-plus"
                                plain
                                @click="dialogVisible = !dialogVisible"
                            ></el-button>
                        </el-col>
                        <el-col :span="24">
                            <el-table ref="shiftTable" :data="shiftTableData">
                                <el-table-column label="星期">
                                    <template slot-scope="scope">
                                        <el-checkbox
                                            v-model="scope.row.enabled"
                                            :label="scope.row.name"
                                        ></el-checkbox>
                                    </template>
                                </el-table-column>
                                <el-table-column label="上班">
                                    <template slot-scope="scope">
                                        <el-time-picker
                                            format="HH:mm"
                                            value-format="HH:mm"
                                            v-model="scope.row.start"
                                        ></el-time-picker>
                                    </template>
                                </el-table-column>
                                <el-table-column label="下班">
                                    <template slot-scope="scope">
                                        <el-time-picker
                                            format="HH:mm"
                                            value-format="HH:mm"
                                            v-model="scope.row.end"
                                        ></el-time-picker>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-col>
                    </el-row>
                    <el-button
                        type="primary"
                        plain
                        class="mt-1"
                        @click="updateShift"
                        :disabled="shiftID == null"
                        >確定修改</el-button
                    >
                    <el-button
                        type="info"
                        plain
                        class="mt-1"
                        :disabled="shiftID == null"
                        @click="showShiftInfo"
                        >還原更表</el-button
                    >
                </el-col>
            </el-row>
        </el-card>
    </div>
</template>

<script>
import firebase from "firebase/compat/app";
export default {
    name: "Shift",
    data() {
        return {
            loading_count: 0,
            table: [],
            shifts: [],
            shiftTableData: [
                {
                    start: "00:00",
                    end: "00:00",
                    enabled: false,
                    name: "一",
                },
                {
                    end: "00:00",
                    start: "00:00",
                    enabled: false,
                    name: "二",
                },
                {
                    start: "00:00",
                    end: "00:00",
                    enabled: false,
                    name: "三",
                },
                {
                    start: "00:00",
                    end: "00:00",
                    enabled: false,
                    name: "四",
                },
                {
                    start: "00:00",
                    end: "00:00",
                    enabled: false,
                    name: "五",
                },
                {
                    start: "00:00",
                    end: "00:00",
                    enabled: false,
                    name: "六",
                },
                {
                    start: "00:00",
                    end: "00:00",
                    enabled: false,
                    name: "日",
                },
            ],
            shiftForm: {
                name: "",
                data: [
                    {
                        start: "00:00",
                        end: "00:00",
                        enabled: false,
                        name: "一",
                    },
                    {
                        start: "00:00",
                        end: "00:00",
                        enabled: false,
                        name: "二",
                    },
                    {
                        start: "00:00",
                        end: "00:00",
                        enabled: false,
                        name: "三",
                    },
                    {
                        start: "00:00",
                        end: "00:00",
                        enabled: false,
                        name: "四",
                    },
                    {
                        start: "00:00",
                        end: "00:00",
                        enabled: false,
                        name: "五",
                    },
                    {
                        start: "00:00",
                        end: "00:00",
                        enabled: false,
                        name: "六",
                    },
                    {
                        start: "00:00",
                        end: "00:00",
                        enabled: false,
                        name: "日",
                    },
                ],
            },
            editForm: {
                name: null,
                shiftID: null,
            },
            shiftID: null,
            //
            dialogVisible: false,
            editDialog: false,
            search: "",
            page: 1,
            pageSize: 8,
        };
    },
    mounted() {
        this.getShifts();
        this.getUsers();
    },
    methods: {
        sortByEngName(a, b) {
            return a.eng_name.toLowerCase() > b.eng_name.toLowerCase() ? 1 : -1;
        },
        onSetting() {
            if (this.editForm.shiftID == null || this.editForm.shiftID == "") {
                this.$message({
                    message: `請選擇更表`,
                    type: "error",
                });
            } else {
                this.loading_count++;
                firebase
                    .database()
                    .ref("users/" + this.empID)
                    .update({
                        shiftID: this.editForm.shiftID,
                    })
                    .then(() => {
                        this.$message({
                            message: `設定成功！`,
                            type: "success",
                        });
                    })
                    .catch((err) => {
                        this.$message({
                            message: `發生錯誤 ${err.code}: ${err.message}`,
                            type: "error",
                        });
                    })
                    .finally(() => {
                        this.editDialog = false;
                        this.loading_count--;
                        this.editForm.shiftID = null;
                        this.getUsers();
                    });
            }
        },
        getUsers() {
            this.table = [];
            const userRef = firebase.database().ref("users");
            userRef
                .once("value")
                .then((snapshot) => snapshot.val())
                .then((value) => {
                    Object.keys(value).forEach((v) => {
                        if (value[v].role != "administrator") {
                            if (value[v].shiftID == null) {
                                this.table.push({
                                    name: value[v].name,
                                    eng_name: value[v].eng_name,
                                    shift: "",
                                    empID: v,
                                });
                            } else {
                                firebase
                                    .database()
                                    .ref(
                                        "company/shifts/shifts/" +
                                            value[v].shiftID
                                    )
                                    .once("value")
                                    .then((s) => s.val())
                                    .then((_v) => {
                                        if (_v == null) {
                                            this.table.push({
                                                name: value[v].name,
                                                eng_name: value[v].eng_name,
                                                shift: "",
                                                empID: v,
                                            });
                                        } else {
                                            this.table.push({
                                                name: value[v].name,
                                                eng_name: value[v].eng_name,
                                                shift: _v.name,
                                                empID: v,
                                            });
                                        }
                                    });
                            }
                        }
                    });
                });
        },
        handleCurrentChange(c) {
            this.page = c;
        },
        updateShift() {
            const shiftRef = firebase
                .database()
                .ref("company/shifts/shifts/" + this.shiftID);
            var updates = {
                1: this.shiftTableData[0],
                2: this.shiftTableData[1],
                3: this.shiftTableData[2],
                4: this.shiftTableData[3],
                5: this.shiftTableData[4],
                6: this.shiftTableData[5],
                7: this.shiftTableData[6],
            };
            shiftRef
                .update(updates)
                .then(() => {
                    this.$message({
                        type: "success",
                        message: `成功修改更表`,
                    });
                })
                .catch((err) => {
                    this.$message({
                        type: "error",
                        message: `發生錯誤 ${err.code}: ${err.message}`,
                    });
                })
                .finally(() => {
                    this.showShiftInfo();
                });
        },
        getShifts() {
            this.loading_count++;
            const shiftsRef = firebase.database().ref("company/shifts/shifts");
            shiftsRef
                .once("value")
                .then((snapshot) => snapshot.val())
                .then((value) => {
                    this.shifts = [];
                    Object.keys(value).forEach((v) => {
                        this.shifts.push({
                            label: value[v].name,
                            value: v,
                        });
                    });
                })
                .finally(() => {
                    this.loading_count--;
                });
        },
        initShiftData() {
            this.shiftForm = {
                name: "",
                data: [
                    {
                        start: "00:00",
                        end: "00:00",
                        enabled: false,
                        name: "一",
                    },
                    {
                        start: "00:00",
                        end: "00:00",
                        enabled: false,
                        name: "二",
                    },
                    {
                        start: "00:00",
                        end: "00:00",
                        enabled: false,
                        name: "三",
                    },
                    {
                        start: "00:00",
                        end: "00:00",
                        enabled: false,
                        name: "四",
                    },
                    {
                        start: "00:00",
                        end: "00:00",
                        enabled: false,
                        name: "五",
                    },
                    {
                        start: "00:00",
                        end: "00:00",
                        enabled: false,
                        name: "六",
                    },
                    {
                        start: "00:00",
                        end: "00:00",
                        enabled: false,
                        name: "日",
                    },
                ],
            };
            this.shiftTableData = [
                {
                    start: "00:00",
                    end: "00:00",
                    enabled: false,
                    name: "一",
                },
                {
                    start: "00:00",
                    end: "00:00",
                    enabled: false,
                    name: "二",
                },
                {
                    start: "00:00",
                    end: "00:00",
                    enabled: false,
                    name: "三",
                },
                {
                    start: "00:00",
                    end: "00:00",
                    enabled: false,
                    name: "四",
                },
                {
                    start: "00:00",
                    end: "00:00",
                    enabled: false,
                    name: "五",
                },
                {
                    start: "00:00",
                    end: "00:00",
                    enabled: false,
                    name: "六",
                },
                {
                    start: "00:00",
                    end: "00:00",
                    enabled: false,
                    name: "日",
                },
            ];
        },
        deleteShift() {
            const shiftRef = firebase
                .database()
                .ref("company/shifts/shifts/" + this.shiftID);
            shiftRef
                .remove()
                .then(() => {
                    this.$message({
                        message: `刪除更表成功`,
                        type: "success",
                    });
                    firebase
                        .database()
                        .ref("users")
                        .once("value")
                        .then((s) => s.val())
                        .then((v) => {
                            Object.keys(v).forEach((_v) => {
                                var shiftID = v[_v].shiftID;
                                if (
                                    shiftID != null &&
                                    this.shiftID == v[_v].shiftID
                                ) {
                                    firebase
                                        .database()
                                        .ref(`users/${_v}/shiftID`)
                                        .remove();
                                }
                            });
                        });
                })
                .then(() => {
                    this.initShiftData();
                    this.getShifts();
                    this.getUsers();
                })
                .finally(() => {
                    this.shiftID = null;
                });
        },
        editShift(empID) {
            this.empID = empID;
            this.loading_count++;
            this.editDialog = true;
            const userRef = firebase.database().ref("users/" + empID);
            userRef
                .once("value")
                .then((snapshot) => snapshot.val())
                .then((value) => {
                    this.editForm.name = value.name;
                })
                .finally(() => {
                    this.loading_count--;
                });
        },
        onAdd() {
            if (this.shiftForm.name == null || this.shiftForm.name == "") {
                this.$message({
                    message: "請輸入更表名稱",
                    type: "error",
                });
            } else {
                var shift = {
                    name: this.shiftForm.name,
                };
                for (const _d in this.shiftForm.data) {
                    const id = parseInt(_d) + 1;
                    var day = [];
                    day[id] = {
                        enabled: this.shiftForm.data[_d].enabled,
                        end: this.shiftForm.data[_d].end,
                        start: this.shiftForm.data[_d].start,
                        name: this.shiftForm.data[_d].name,
                    };
                    shift = Object.assign(shift, day);
                }
                this.loading_count++;
                firebase
                    .database()
                    .ref("company/shifts/shifts")
                    .push(shift)
                    .then(() => {
                        this.$message({
                            message: `增加班次成功`,
                            type: "success",
                        });
                        this.getShifts();
                    })
                    .finally(() => {
                        this.initShiftData();

                        this.dialogVisible = false;
                        this.loading_count--;
                    });
            }
        },
        showShiftInfo() {
            this.loading_count++;
            const ShiftRef = firebase
                .database()
                .ref("company/shifts/shifts/" + this.shiftID);
            ShiftRef.once("value")
                .then((snapshot) => snapshot.val())
                .then((value) => {
                    const _temp = value;
                    delete _temp.name;
                    this.shiftTableData = Object.values(_temp);
                })
                .finally(() => {
                    this.loading_count--;
                });
        },
    },
    computed: {
        loading() {
            return this.loading_count != 0;
        },
        pagedTableData() {
            return this.tableData.slice(
                this.pageSize * this.page - this.pageSize,
                this.pageSize * this.page
            );
        },
        total() {
            return this.tableData.length;
        },
        tableData() {
            return this.table.filter(
                (data) =>
                    !this.search ||
                    data.name
                        .toLowerCase()
                        .includes(this.search.toLowerCase()) ||
                    data.shift
                        .toLowerCase()
                        .includes(this.search.toLowerCase()) ||
                    data.eng_name
                        .toLowerCase()
                        .includes(this.search.toLowerCase())
            );
        },
    },
};
</script>
