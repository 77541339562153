<template>
    <div class="main">
        <el-dialog :visible.sync='dialogVisible' width='20%' center title='請假審批'>
            <center class='mt-2'><el-button type='success' @click='approveLeave' style="width: 40%;" icon='el-icon-check' plain>批准</el-button> <el-button icon='el-icon-close' type='danger' style="width: 40%;" @click='rejectLeave'  plain>駁回</el-button></center>
            <center class='mt-1'><el-button type='text' @click='dialogVisible = !dialogVisible'>稍後處理</el-button> </center>
        </el-dialog>
        <el-card class="card-box" shadow='never' v-loading='loading'>
            <div slot='header'>
                <el-breadcrumb separator='/'>
                    <el-breadcrumb-item to='/'>主頁</el-breadcrumb-item>
                    <el-breadcrumb-item>審核</el-breadcrumb-item>
                    <el-breadcrumb-item>請假</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
            <el-card shadow="never">
                <div slot="header">篩選</div>
                <el-form :inline="true" ref="filter">
                    <el-form-item>
                        <el-select v-model='filter.user' clearable placeholder='員工' filterable>
                            <el-option v-for='(user, i) in userList' :key='i' :label='user.label' :value='user.value'>
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-select v-model='filter.type' clearable placeholder='類型'>
                            <el-option value='病假' label='病假'></el-option>
                            <el-option value='事假' label='事假'></el-option>
                            <el-option value='年假' label='年假'></el-option>
                            <el-option value='產假' label='產假'></el-option>
                            <el-option value='陪產假' label='陪產假'></el-option>
                            <el-option value='婚假' label='婚假'></el-option>
                            <el-option value='喪假' label='喪假'></el-option>
                            <el-option value='哺乳假' label='哺乳假'></el-option>
                            <el-option value='其他' label='其他'></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-select v-model='filter.status' clearable placeholder='狀態' filterable>
                            <el-option value='Pending' label='待處理'></el-option>
                            <el-option value='Approved' label='批准'></el-option>
                            <el-option value='Rejected' label='駁回'></el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
            </el-card>
            <el-table :data="tableData" height='650'>
                <el-table-column label="員工" prop="name" sortable> </el-table-column>
                <el-table-column label="類型" sortable>
                    <template slot-scope="scope">
                        <el-tag type='primary'>{{scope.row.type}}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="原因" prop="description" sortable>
                </el-table-column>
                <el-table-column label="申請日期" prop="created_at" sortable>
                </el-table-column>
                <el-table-column label="開始日期" prop="startTimestamp" sortable>
                </el-table-column>
                <el-table-column label="結束日期" prop="endTimestamp" sortable>
                </el-table-column>

                <el-table-column label="狀態" sortable>
                    <template slot-scope="scope">
                        <el-tag
                            type="danger"
                            v-if="scope.row.status == 'Rejected'"
                        >
                            駁回
                        </el-tag>
                        <el-tag
                            type="success"
                            v-if="scope.row.status == 'Approved'"
                        >
                            批准
                        </el-tag>
                        <el-tag
                            type="warning"
                            v-if="scope.row.status == 'Pending'"
                        >
                            待處理
                        </el-tag>

                    </template>
                </el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button
                            type="primary"
                            plain
                            circle
                            icon="el-icon-edit-outline"
                            @click='dialogVisible = !dialogVisible; editForm.userID = scope.row.userID; editForm.leaveID = scope.row.leaveID'
                        ></el-button>
                        <el-popconfirm
                            cancel-button-text='不用了'
                            confirm-button-text='好的'
                            title='確認刪除此請假紀錄'
                            @confirm='deleteLeave(scope)'
                            style="margin-left: 1em"
                            :class="scope.row.status != 'Pending' ? 'pop-left': ''"
                            >
                            <el-button
                                type="danger"
                                plain
                                circle
                                slot='reference'
                                icon="el-icon-delete"
                            ></el-button>
                        </el-popconfirm>
                    </template>
                </el-table-column>
            </el-table>
        </el-card>
        

    </div>
</template>
<style>
</style>
<script>
//import firebase from 'firebase/compat/app'
import app from '@/firebase'
export default {
    name: 'Leave',
    data(){
        return {
            filter: {
                order: '',
                status: '',
            },
            table: [
            ],
            loading_count : 0,
            userList: [],
            dialogVisible: false,
            editForm: {
                userID: '',
                leaveID: '',
            }
        }
    },
    computed: {
        loading() {
            return !!this.loading_count
        },
        tableData(){
            return this.table.filter(data => {
                var a = (this.filter.user && this.filter.user == data.name) || !this.filter.user
                var b = (this.filter.type && this.filter.type == data.type) || !this.filter.type
                var c = (this.filter.status && this.filter.status == data.status) || !this.filter.status
                return a && b && c
            })
        }
    },
    methods: {
        approveLeave(){
            const userID = this.editForm.userID;
            const leaveID = this.editForm.leaveID;

            app.approveLeave(userID, leaveID).then(()=>{
                this.$message({
                    message: `該請假申請的狀態已更變為 批准`,
                    type: 'success'
                })
                this.dialogVisible = false
                this.getLeaves()
            })
        },
        rejectLeave(){
            const userID = this.editForm.userID;
            const leaveID = this.editForm.leaveID;

            app.rejectLeave(userID, leaveID).then(()=>{
                this.$message({
                    message: `該請假申請的狀態已更變為 駁回`,
                    type: 'success'
                })
                this.dialogVisible = false
                this.getLeaves()
            })

        },
        getUsers(){
            this.userList = []
            this.loading_count ++
            app.getUserList().then(value=>{
                this.userList = value
                this.loading_count --
            })
        },
        getLeaves(){
            this.table = []
            this.loading_count ++
            app.getLeaveList().then(value => {
                this.table = value
                this.loading_count --
            })
        },
        deleteLeave(scope){
            const userID = scope.row.userID;
            const leaveID = scope.row.leaveID;
            this.loading_count ++
            app.deleteLeave(userID, leaveID).then(()=>{
                this.$message({
                    message: `成功刪除請假紀錄`,
                    type: 'success'
                })
                this.loading_count --
                this.getLeaves()
            })
        },
        /** format Time */
        formatTime(ts){
            var d = new Date(ts)
            return d.toString().slice(16,24)
        },
        /** format Date */
        formatDate(ts){
            let d = new Date(ts)
            return this.formatTen(d.getFullYear()) + '-' + this.formatTen(d.getMonth() + 1) + '-' + this.formatTen(d.getDate());
        },
        /** format 10 */
        formatTen(n){
            return n < 10 ? '0' + n : n
        },
        /** format */
        format(ts){
            return this.formatDate(ts) + ' ' + this.formatTime(ts)
        },
    },
    mounted() {
        this.getLeaves()
        this.getUsers()

    }
}
</script>